import React, { useState, useCallback, useRef, useEffect } from "react"
import styled from "styled-components"
import ZenlinkDexProtrolSvg from "./ZenlinkDEXProtocol.svg"
import PlugSvg from "./plug.svg"
import DeployDexOne from "./DeployDexOne.svg"
import AggregatorSvg from "./Aggregator.svg"
import StrictLine from "./StrictLine.svg"
import { breakpoints } from "../const"
import DexExperience from "./DexExperience2.png"
import CrossChain from "./CrossChain2.png"
import FullLiquid from "./FullLiquid2.png"
import { useIntl } from "gatsby-plugin-intl"

const FrameWorkWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: -100px;
  @media screen and (max-width: ${breakpoints.md}px) {
    margin-bottom: -100px;
  }
`
const FrameWorkTitle = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: #11131a;
  line-height: 58px;
  background: linear-gradient(137deg, #f20082 0%, #9c00d5 50%, #3f58f0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const ItemWrapper = styled.div`
  margin: 0px 20px;
  width: 332px;
  height: 369px;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.04) 100%
  );
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.66);
  border-radius: 12px;
  border: 1px solid;
  border-color: rgba(255, 255, 255, 0.3);
  padding: 24px;
  /* border-image: linear-gradient(137deg, rgba(255, 255, 255, 0.3), rgba(151, 151, 151, 0.1)) 1 1; */
`

const ItemTitle = styled.div`
  font-size: 32px;
  font-weight: bold;
  color: #ffffff;
  line-height: 32px;
  background: linear-gradient(137deg, #f20082 0%, #9c00d5 50%, #3f58f0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
const ItemIcon = styled.div``
const ItemDivider = styled.div``
const ItemDesc = styled.div`
  width: 242px;
  height: 72px;
  font-size: 16px;
  color: #a4a8b3;
  line-height: 24px;
`
const SubTitle = styled.div`
  position: relative;
  width: 226px;
  height: 58px;
  font-size: 48px;
  font-weight: bold;
  color: #11131a;
  line-height: 58px;
  background: linear-gradient(137deg, #f20082 0%, #9c00d5 50%, #3f58f0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 190px;
  margin-top: 100px;
  @media screen and (max-width: ${breakpoints.md}px) {
    font-size: 32px;
    margin-bottom: 50px;
    margin-top: 70px;
  }
`
const TimeLineWrapper = styled.div`
  position: relative;
  border-left: 1px solid rgba(155, 155, 155, 0.3);
  margin-right: 25px;
  margin-left: 30px;
  /* margin-bottom: 160px; */
  @media screen and (max-width: ${breakpoints.md}px) {
    /* text-align: center; */
    /* margin-left: 0px; */
    margin-left: 0px;
  }
`
const ContentWrapper = styled.div`
  flex-grow: 1;
`
const ContentItemWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  /* align-items: center; */
  /* margin-bottom: 320px; */
  @media screen and (max-width: ${breakpoints.md}px) {
    /* text-align: center; */
    flex-direction: column;
    margin-bottom: 92px;
  }
`
const ContentInfoWrapper = styled.div`
  margin-top: 0px;
  margin-bottom: 200px;
  @media screen and (max-width: ${breakpoints.md}px) {
    margin-top: 0px;
    margin-bottom: 40px;
  }
`
const ContentInfoTitle = styled.div`
  font-size: 32px;
  font-weight: bold;
  color: #242833;
  line-height: 39px;
  @media screen and (max-width: ${breakpoints.md}px) {
    font-size: 24px;
  }
`
const ContentInfoDesc = styled.div`
  font-size: 17px;
  color: #393e4d;
  line-height: 27px;
  margin-top: 24px;
  max-width: 400px;
  @media screen and (max-width: ${breakpoints.md}px) {
    margin-top: 20px;
    font-size: 14px;
  }
`
const ImageWrapperWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  transform: translateY(-25%);
  @media screen and (max-width: ${breakpoints.xl}px) {
  }
  @media screen and (max-width: ${breakpoints.md}px) {
    transform: translateY(0px);
  }
`
const ImageWrapper = styled.img`
  width: 100%;
  max-width: 760px;
  min-width: 300px;
  /* height: 502px; */
  /* background-size: cover; */
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  margin-bottom: 140px;
  margin-left: 20px;
  object-fit: contain;
  @media screen and (max-width: ${breakpoints.xl}px) {
    /* width: 363px;
    height: 340px; */
    /* min-width: 303px;
    width: 303px; */
  }
  @media screen and (max-width: ${breakpoints.md}px) {
    min-width: 200px;
    margin-bottom: 10px;
    max-height: 300px;
    /* width: 303px; */
    margin-left: 0px;
    background-position: right;

    transform: translateX(15px);
    /* height: 340px; */
  }
`
const CorlorCircle = styled.div`
  position: absolute;
  left: -35px;
  top: 15px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #f20082;
  border: 1px solid #feb64e;
`
const BottomCorlorCircle = styled.div`
  left: -11px;
  position: absolute;
  bottom: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #f20082;
  border: 1px solid #feb64e;
  bottom: 102px;
  @media screen and (max-width: ${breakpoints.md}px) {
    bottom: 102px;
  }
`
const GreenCircle = styled.div`
  position: absolute;
  left: -31px;
  top: 15px;
  border-radius: 50%;
  width: 11px;
  height: 11px;
  background: #4fceac;
  border: 5px solid rgba(79, 206, 172, 0.25);
`
function ModuleItem(props) {
  const { icon, title, desc, bottom } = props
  return (
    <ItemWrapper>
      <ItemIcon>{icon}</ItemIcon>
      <ItemDivider />
      <ItemTitle>{title}</ItemTitle>
      <ItemDesc>{desc}</ItemDesc>
    </ItemWrapper>
  )
}

export default function (props) {
  const intl = useIntl()
  return (
    <>
      <FrameWorkWrapper>
        <TimeLineWrapper>
          <BottomCorlorCircle />
          {/* <StrictLine /> */}
        </TimeLineWrapper>
        <ContentWrapper>
          <SubTitle>
            <CorlorCircle />
            For Users
          </SubTitle>
          <ContentItemWrapper>
            <GreenCircle />
            <ContentInfoWrapper>
              <ContentInfoTitle>
                {intl.formatMessage({ id: "Share the Full Liquidity" })}
              </ContentInfoTitle>
              <ContentInfoDesc>
                {intl.formatMessage({
                  id:
                    "Users will be able to enjoy a low fees, low slippage and high speed trading experience with Zenlink",
                })}
              </ContentInfoDesc>
            </ContentInfoWrapper>
            <ImageWrapperWrapper>
              <ImageWrapper
                src={FullLiquid}
                // style={{
                //   backgroundImage: `url(${FullLiquid})`,
                // }}
              />
            </ImageWrapperWrapper>
          </ContentItemWrapper>
          <ContentItemWrapper>
            <GreenCircle />
            <ContentInfoWrapper>
              <ContentInfoTitle>
                {intl.formatMessage({ id: "Perception-free Cross Chain" })}
              </ContentInfoTitle>
              <ContentInfoDesc>
                {intl.formatMessage({
                  id:
                    "Zenlink provides users with a real and convenient cross-chain transaction experience without the perception of multiple chains",
                })}
              </ContentInfoDesc>
            </ContentInfoWrapper>
            <ImageWrapperWrapper>
              <ImageWrapper
                src={CrossChain}
                // style={{
                //   backgroundImage: `url(${CrossChain})`,
                // }}
              />
            </ImageWrapperWrapper>
          </ContentItemWrapper>
          <ContentItemWrapper
            style={
              {
                // marginBottom: "-100px",
              }
            }
          >
            <GreenCircle />
            <ContentInfoWrapper>
              <ContentInfoTitle>
                {intl.formatMessage({
                  id: "Rich Composability DEX Experience",
                })}
              </ContentInfoTitle>
              <ContentInfoDesc>
                {intl.formatMessage({
                  id:
                    "Each parachain can build a DEX with its own characteristics by connecting to the Zenlink DEX Module. Users can easily experience different types of DEX",
                })}
              </ContentInfoDesc>
            </ContentInfoWrapper>
            <ImageWrapperWrapper>
              <ImageWrapper
                src={DexExperience}
                // style={{
                //   backgroundImage: `url(${DexExperience})`,
                // }}
              />
            </ImageWrapperWrapper>
          </ContentItemWrapper>
        </ContentWrapper>
      </FrameWorkWrapper>
    </>
  )
}
