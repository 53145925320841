import React, { useState, useCallback, useRef, useEffect } from "react"
import styled from "styled-components"
import ZenlinkDexProtrolSvg from "./ZenlinkDEXProtocol.svg"
import { breakpoints } from "../const"
import LeftSvg from "./Left.svg"
import RightSvg from "./right.svg"
import { parse } from "rss-to-json"

const FrameWorkWrapper = styled.div`
  overflow: visible;
  display: flex;
  transition: all 0.6s ease 0s;
  /* justify-content: center; */
`
const FrameWorkInner = styled.div`
  display: flex;
  /* transform: translateX(-50%); */
  /* justify-content: center; */
`
const FrameWorkTitle = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: #11131a;
  line-height: 58px;
  background: linear-gradient(137deg, #f20082 0%, #9c00d5 50%, #3f58f0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const ItemWrapper = styled.div`
  position: relative;
  margin: 0px 20px;
  padding: 1px;
  /* width: 400px; */
  height: 465px;
  width: 352px;
  /* box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.66); */
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.3);

  :hover {
    padding: 2px;
    background: linear-gradient(135deg, #f20082 0%, #9c00d5 50%, #3f58f0 100%);
  }
  @media screen and (max-width: ${breakpoints.md}px) {
    font-size: 18px;
    width: 277px;
    height: 415px;
  }
  /* border-image: linear-gradient(137deg, rgba(255, 255, 255, 0.3), rgba(151, 151, 151, 0.1)) 1 1; */
`
const ItemWrapperInner = styled.div`
  position: relative;
  /* width: 100%; */
  /* height: 100%; */
  padding: 24px;
  /* width: 400px; */
  height: 100%;
  background: linear-gradient(90deg, rgb(9 7 45) 0%, rgb(32, 15, 55) 100%);
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.66);
  border-radius: 12px;
  border-image: linear-gradient(
      137deg,
      rgba(255, 255, 255, 0.3),
      rgba(151, 151, 151, 0.1)
    )
    1 1;
  :hover {
    padding: 23px;
  }
  /* border-image: linear-gradient(137deg, rgba(255, 255, 255, 0.3), rgba(151, 151, 151, 0.1)) 1 1; */
`

const ItemTitle = styled.div`
  margin-top: 181px;
  /* width: 352px; */
  height: 58px;
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  line-height: 29px;
  @media screen and (max-width: ${breakpoints.md}px) {
    font-size: 18px;
    /* width: 277px; */
  }
`
const ItemTitleLink = styled.a`
  font-weight: medium;
  color: #ffffff;
  text-align: left;
`
const ItemIcon = styled.div`
  width: 400px;
  height: 181px;
`
const ItemDivider = styled.div``
const ItemDesc = styled.div`
  width: 352px;
  height: 63px;
  font-size: 14px;
  color: #d0d2d9;
  line-height: 21px;
`
const ItemDate = styled.div`
  position: absolute;
  bottom: 20px;
  height: 17px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  line-height: 17px;
`
const ItemImg = styled.div`
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 181px;
  font-size: 14px;
  color: #ffffff;
  line-height: 17px;
`

const SwitchButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  /* border-image: linear-gradient(135deg, rgba(242, 0, 130, 1), rgba(156, 0, 213, 1), rgba(63, 88, 240, 1)) 1 1; */
`
const SwitchButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid;
  border-color: #9c00d5;
  border-radius: 50%;
  margin: 20px 20px;
  color: #9c00d5;
  cursor: pointer;
  /* border-image: linear-gradient(135deg, rgba(242, 0, 130, 1), rgba(156, 0, 213, 1), rgba(63, 88, 240, 1)) 1 1; */
`

function FrameworkItem(props, ref) {
  const { icon, title, desc, bottom, img, link, pubDate } = props
  return (
    <ItemWrapper ref={ref}>
      <ItemTitleLink href={link} target="_blank" rel="noopener noreferrer">
        <ItemWrapperInner>
          <ItemImg
            src={img}
            style={{
              backgroundImage: `url(${img})`,
            }}
          />
          {/* <ItemIcon>{icon}</ItemIcon> */}
          <ItemDivider />
          <ItemTitle>
            <ItemTitleLink
              href={link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {title}
            </ItemTitleLink>
          </ItemTitle>
          <ItemDate>{pubDate}</ItemDate>
        </ItemWrapperInner>
      </ItemTitleLink>

      {/* <ItemDesc>{desc}</ItemDesc> */}
    </ItemWrapper>
  )
}
const ItemWrapperRef = React.forwardRef(FrameworkItem)

export default function (props) {
  const [list, setList] = useState([])
  const [current, setCurrent] = useState(0)
  useEffect(() => {
    // fetch(
    //   "https://api.rss2json.com/v1/api.json?rss_url=https:%2F%2Fmedium.com%2Ffeed%2Fzenlinkpro"
    // )
    parse(
      "https://mediumrss.haike.workers.dev/api?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40zenlinkpro"
    )
      // .then(res => res.json())
      .then(json => {
        const items = (json.items || []).map(item => {
          let domParser = new DOMParser()
          const xmlString = `${item.content}`
          let doc = domParser.parseFromString(xmlString, "text/html")
          const image = doc.querySelector("figure>img")
          let thumbnail = ""
          if (image) {
            thumbnail = image.src
          }
          return {
            thumbnail,
            ...item,
          }
        })
        setList(items || [])
        // console.log("retainable-rss-embed", json)
      })
    // var container = document.getElementById("retainable-rss-embed");
    // console.log('retainable-rss-embed', container);
    // if (container) {
    //   console.log('retainable-rss-embed', container);
    //     var css = document.createElement('link');
    //     css.href = "https://www.twilik.com/assets/retainable/rss-embed/retainable.css";
    //     css.rel = "stylesheet"
    //     document.getElementsByTagName('head')[0].appendChild(css);
    //     var script = document.createElement('script');
    //     script.src = "https://www.twilik.com/assets/retainable/rss-embed/retainable.js";
    //     document.getElementsByTagName('body')[0].appendChild(script);
    // }
  }, [])
  const frameWrapperRef = useRef()
  const [transedX, setTransedX] = useState(0)
  const lastRef = useRef()
  const handleStep = useCallback(
    step => {
      let roadMapWidth = frameWrapperRef.current.getBoundingClientRect
      if (!lastRef.current) return
      let rect = lastRef.current.getBoundingClientRect()
      let bigRight = rect.x
      let tTramslateX = transedX + (rect.width + 40) * step
      if (step > 0) {
        tTramslateX =
          window.innerWidth > rect.x + rect.width + 40 ? transedX : tTramslateX // right: ;
      } else {
        tTramslateX = tTramslateX < 0 ? 0 : tTramslateX // left
      }

      setTransedX(tTramslateX)
      frameWrapperRef.current.style.transform = `translateX(${-tTramslateX}px)`
    },
    [transedX]
  )
  return (
    <>
      <FrameWorkWrapper ref={frameWrapperRef}>
        <FrameWorkInner>
          {list.map((item, index) => {
            return (
              <ItemWrapperRef
                key={index}
                ref={lastRef}
                desc={
                  "The underlying unified and universal cross-chain DEX Protocol."
                }
                img={item.thumbnail}
                icon={<ZenlinkDexProtrolSvg />}
                pubDate={item.pubDate}
                title={item.title}
                link={item.link}
              />
            )
          })}
        </FrameWorkInner>
        {/* <div  ref={lastRef}/> */}
      </FrameWorkWrapper>
      <SwitchButtonWrapper>
        <SwitchButton
          onClick={() => {
            handleStep(-1)
          }}
        >
          <RightSvg />
        </SwitchButton>
        <SwitchButton
          onClick={() => {
            handleStep(1)
          }}
        >
          <LeftSvg />
        </SwitchButton>
      </SwitchButtonWrapper>
    </>
  )
}
