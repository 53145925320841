import React, { useState, useCallback, useRef, useEffect } from "react"
import styled from "styled-components"
import ZenlinkDexProtrolSvg from "./ZenlinkDEXProtocol.svg"
import ModuleSvg from "./Module.svg"
import AggregatorSvg from "./Aggregator.svg"
import NetworkSvg from "./Network.svg"
import PlugSvg from "./plugCircle.svg"
import UpdateSvg from "./updateCircle.svg"
import CombinabilitySvg from "./CombinabilityCircle.svg"
import { breakpoints } from "../const"
import { useIntl } from "gatsby-plugin-intl"

const FrameWorkWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`
const FrameWorkWrapperBottom = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 140px;
  @media screen and (max-width: ${breakpoints.md}px) {
    margin-bottom: 70px;
  }
`
const FrameWorkTitle = styled.span`
  margin: 100px 0px;
  margin-top: 200px;
  width: 268px;
  height: 58px;
  font-size: 48px;
  font-weight: bolder;
  color: #11131a;
  line-height: 58px;
  background: linear-gradient(137deg, #f20082 0%, #9c00d5 50%, #3f58f0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  @media screen and (max-width: ${breakpoints.md}px) {
    font-size: 32px;
    margin-top: 50px;
    margin-bottom: 40px;
  }
`

const ItemWrapper = styled.div`
  position: relative;
  margin: 40px 0px;
  width: 22%;
  height: 369px;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.04) 100%
  );
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.66);
  border-radius: 12px;
  border: 1px solid;
  border-color: rgba(255, 255, 255, 0.1);
  /* border-image: linear-gradient(137deg, rgba(255, 255, 255, 0.3), rgba(151, 151, 151, 0.1)) 1 1; */
  padding: 24px;
  padding-top: 48px;
  @media screen and (max-width: ${breakpoints.lg}px) {
    /* text-align: center; */
    width: 45%;
  }
  @media screen and (max-width: ${breakpoints.md}px) {
    /* text-align: center; */
    padding-top: 24px;
    width: 90%;
    height: 194px;
  }
  /* border-image: linear-gradient(137deg, rgba(255, 255, 255, 0.3), rgba(151, 151, 151, 0.1)) 1 1; */
`

const ItemTitle = styled.div`
  /* width: 242px; */
  height: 72px;
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  line-height: 36px;
  @media screen and (max-width: ${breakpoints.md}px) {
    /* text-align: center; */
    height: 32px;
    line-height: 24px;
    font-size: 16px;
  }
`
const ItemIcon = styled.div`
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0%;
  transform: translateY(-50%);
  @media screen and (max-width: ${breakpoints.md}px) {
    /* text-align: center; */
    width: 48px;
    height: 48px;
  }
`
const ItemDivider = styled.div`
  width: 25px;
  height: 1px;
  margin: 20px 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
`
const ItemDesc = styled.div`
  /* width: 242px; */
  height: 72px;
  font-size: 16px;
  color: #a4a8b3;
  line-height: 24px;
  @media screen and (max-width: ${breakpoints.md}px) {
    /* text-align: center; */
    color: #d0d2d9;
    line-height: 18px;
    font-size: 12px;
    height: 36px;
  }
`
const BottomIconWrapper = styled.div`
  position: absolute;
  bottom: 40px;
  display: flex;
  /* margin-top: 44px; */
  height: 20px;
  @media screen and (max-width: ${breakpoints.md}px) {
    /* text-align: center; */
    bottom: 20px;
  }
`
const BottomIcon = styled.div`
  margin-right: 10px;
`

function FrameworkItem(props) {
  const { icon, title, desc, bottom } = props
  return (
    <ItemWrapper>
      <ItemIcon>{icon}</ItemIcon>
      <ItemTitle>{title}</ItemTitle>
      <ItemDivider />
      <ItemDesc>{desc}</ItemDesc>
      <BottomIconWrapper>{bottom}</BottomIconWrapper>
    </ItemWrapper>
  )
}

export default function (props) {
  const intl = useIntl()
  return (
    <>
      <FrameWorkWrapper>
        <FrameWorkTitle>Framework</FrameWorkTitle>
      </FrameWorkWrapper>
      <FrameWorkWrapperBottom
        style={{
          marginBottom: "70px",
        }}
      >
        <FrameworkItem
          desc={intl.formatMessage({
            id: "The underlying unified and universal cross-chain DEX Protocol",
          })}
          icon={<ZenlinkDexProtrolSvg />}
          title={"Zenlink DEX Protocol"}
        />
        <FrameworkItem
          desc={intl.formatMessage({
            id:
              "Plug-and-Play Polkadot Network Module based on the Zenlink DEX Protocol standard",
          })}
          icon={<ModuleSvg />}
          title={"Zenlink DEX Module"}
          bottom={
            <>
              <BottomIcon>
                <PlugSvg />
              </BottomIcon>
              <BottomIcon>
                <UpdateSvg />
              </BottomIcon>
              <BottomIcon>
                <CombinabilitySvg />
              </BottomIcon>
            </>
          }
        />
        <FrameworkItem
          desc={intl.formatMessage({
            id: "Aggregator for connecting all DEX DApps on Polkadot",
          })}
          icon={<AggregatorSvg />}
          title={"Zenlink DEX Aggregator"}
        />
        <FrameworkItem
          desc={intl.formatMessage({
            id:
              "Each parachain builds a DEX with its own characteristics by connecting to the Zenlink DEX Module",
          })}
          icon={<NetworkSvg />}
          title={"Zenlink DEX Composable Hub"}
        />
      </FrameWorkWrapperBottom>
    </>
  )
}
