import React, { useState, useCallback, useRef, useEffect } from "react"
import styled from "styled-components"
import ZenlinkDexProtrolSvg from "./ZenlinkDEXProtocol.svg"
import PlugSvg from "./plug.svg"
import UpdateSvg from "./update.svg"
import CombinabilitySvg from "./Combinability.svg"
import { breakpoints } from "../const"
import { useIntl } from "gatsby-plugin-intl"

const FrameWorkWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`
const FrameWorkTitle = styled.span`
  width: 477px;
  height: 59px;
  font-size: 48px;
  font-weight: bolder;
  color: #11131a;
  line-height: 58px;
  background: linear-gradient(137deg, #f20082 0%, #9c00d5 50%, #3f58f0 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 100px 0px;
  margin-top: 140px;
  text-align: center;
  @media screen and (max-width: ${breakpoints.md}px) {
    font-size: 32px;
    margin-top: 70px;
    margin-bottom: 50px;
  }
`

const ItemWrapper = styled.div`
  position: relative;
  margin: 15px 0px;
  min-height: 300px;
  width: 30%;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.04) 100%
  );
  box-shadow: rgb(156 0 213 / 45%) 0px 3px 7px 0px;
  border-radius: 12px;
  border: 1px solid;
  border-color: rgba(255, 255, 255, 0.3);
  padding: 24px;
  @media screen and (max-width: ${breakpoints.lg}px) {
    /* text-align: center; */
    /* width: 90%; */
  }
  @media screen and (max-width: ${breakpoints.md}px) {
    width: 90%;
    font-size: 24px;
    min-height: 220px;
  }
  /* border-image: linear-gradient(137deg, rgba(255, 255, 255, 0.3), rgba(151, 151, 151, 0.1)) 1 1; */
`
const ItemWrapperInnerBg = styled.div`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fef8ff;
  box-shadow: 0px 3px 7px 0px #9c00d573;
  border-radius: 12px;
  border: 1px solid;
  border-image: linear-gradient(
      137deg,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0.52)
    )
    1 1;
  filter: blur(23.25px);
  @media screen and (max-width: ${breakpoints.lg}px) {
    /* text-align: center; */
    width: 90%;
  }
  /* border-image: linear-gradient(137deg, rgba(255, 255, 255, 0.3), rgba(151, 151, 151, 0.1)) 1 1; */
`
const ItemWrapperInner = styled.div`
  position: relative;
  z-index: 10;

  /* border-image: linear-gradient(137deg, rgba(255, 255, 255, 0.3), rgba(151, 151, 151, 0.1)) 1 1; */
`

const ItemTitle = styled.div`
  /* width: 266px; */
  margin-top: 20px;
  font-size: 28px;
  height: 60px;
  margin-bottom: 30px;
  font-weight: bolder;
  color: #ffffff;
  line-height: 32px;
  background: linear-gradient(137deg, #f20082 0%, #9c00d5 50%, #3f58f0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  @media screen and (max-width: ${breakpoints.md}px) {
    font-size: 24px;
    margin-bottom: 20px;
  }
`
const ItemIcon = styled.div`
  /* width: 40px; */
  height: 40px;
  display: flex;
  justify-content: center;
`
const ItemDivider = styled.div``
const ItemDesc = styled.div`
  text-align: center;
  /* width: 352px; */
  font-size: 16px;
  color: #393e4d;
  line-height: 24px;
  @media screen and (max-width: ${breakpoints.md}px) {
    font-size: 14px;
  }
`

function ModuleItem(props) {
  const { icon, title, desc, bottom } = props
  return (
    <ItemWrapper>
      <ItemWrapperInnerBg />
      <ItemWrapperInner>
        <ItemIcon>{icon}</ItemIcon>
        <ItemDivider />
        <ItemTitle>{title}</ItemTitle>
        <ItemDesc>{desc}</ItemDesc>
      </ItemWrapperInner>
    </ItemWrapper>
  )
}

export default function (props) {
  const intl = useIntl()
  return (
    <>
      <FrameWorkWrapper>
        <FrameWorkTitle>Zenlink DEX Module</FrameWorkTitle>
      </FrameWorkWrapper>
      <FrameWorkWrapper
        style={{
          marginBotton: "20px",
        }}
      >
        <ModuleItem
          desc={intl.formatMessage({
            id:
              "Plug the Zenlink DEX Module into the parachain code by way of a pallet",
          })}
          icon={<PlugSvg />}
          title={intl.formatMessage({ id: "Plug and Play" })}
        />
        <ModuleItem
          desc={intl.formatMessage({
            id:
              "Replace the Zenlink DEX Module to upgrade to the latest version and benefit from more powerful features",
          })}
          icon={<UpdateSvg />}
          title={intl.formatMessage({ id: "Upgrade Flexibly" })}
        />
        <ModuleItem
          desc={intl.formatMessage({
            id:
              "Enables customizable combinations and interoperability with more DeFi modules",
          })}
          icon={<CombinabilitySvg />}
          title={intl.formatMessage({
            id: "Composability and Interoperability",
          })}
        />
      </FrameWorkWrapper>
    </>
  )
}
