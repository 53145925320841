import React, { useState, useCallback, useRef, useEffect } from "react"
import styled from "styled-components"
import ZenlinkDexProtrolSvg from "./ZenlinkDEXProtocol.svg"
import HashKeySvg from "./hashkey.svg"
import IosgSvg from "./iosg.svg"
import ContinueCapitalSvg from "./ContinueCapital.svg"
import YoubiSvg from "./youbi.svg"
import CiSvg from "./ci.svg"
import { breakpoints } from "../const"
import AlamedaResearchSvg from "./AlamedaResearch.svg"
import OKExBlockdreamSvg from "./OKExBlockdream.svg"
import HypersphereSvg from "./Hypersphere.svg"
import SNZHoldingSvg from "./SNZHolding.svg"
import SevenXSvg from "./SevenX.svg"
import DFGSvg from "./DFG.svg"
import TRGCapitaSvg from "./TRGCapita.svg"
import PAKASvg from "./PAKA.svg"

const FrameWorkWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  @media screen and (max-width: ${breakpoints.md}px) {
    padding: 0 10px;
  }
`
const FrameWorkTitle = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: #11131a;
  line-height: 58px;
  background: linear-gradient(137deg, #f20082 0%, #9c00d5 50%, #3f58f0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const ItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  margin: 20px 0px;
  width: 18%;
  width: 240px;
  margin: 10px 10px;
  :hover {
    background: linear-gradient(135deg, #f20082 0%, #9c00d5 50%, #3f58f0 100%);
  }
  @media screen and (max-width: ${breakpoints.xl}px) {
    /* text-align: center; */
    width: 30%;
    width: 240px;
  }
  @media screen and (max-width: ${breakpoints.md}px) {
    /* text-align: center; */
    width: 40%;
    flex-grow: 1;
    /* width: 154px; */
    height: 76px;
  }
  /* border-image: linear-gradient(137deg, rgba(255, 255, 255, 0.3), rgba(151, 151, 151, 0.1)) 1 1; */
`
const ItemWrapperEmpty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin: 20px 0px;
  width: 240px;
  margin: 10px 10px;
  @media screen and (max-width: ${breakpoints.xl}px) {
    /* text-align: center; */
    width: 30%;
    width: 240px;
  }
  @media screen and (max-width: ${breakpoints.md}px) {
    /* text-align: center; */
    width: 40%;
    flex-grow: 1;
    /* width: 154px; */
  }
  /* border-image: linear-gradient(137deg, rgba(255, 255, 255, 0.3), rgba(151, 151, 151, 0.1)) 1 1; */
`

const ItemIcon = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ItemIconLink = styled.a`
  width: 100%;
  height: 100%;
`

function InvestorItem(props) {
  const { icon, title, desc, bottom, link } = props
  return (
    <ItemWrapper>
      <ItemIconLink href={link} target="_blank">
        <ItemIcon>{icon}</ItemIcon>
      </ItemIconLink>
    </ItemWrapper>
  )
}

export default function (props) {
  return (
    <>
      <FrameWorkWrapper>
        <InvestorItem
          icon={<AlamedaResearchSvg />}
          link="https://www.alameda-research.com/"
        />
        <InvestorItem
          icon={<HashKeySvg />}
          link="https://www.hashkeycap.com/"
        />
        <InvestorItem icon={<IosgSvg />} link="https://iosg.vc/" />
        <InvestorItem
          icon={<ContinueCapitalSvg />}
          link="https://continue.capital/"
        />
        <InvestorItem
          icon={<YoubiSvg />}
          link="https://www.youbicapital.com/"
        />
        <InvestorItem icon={<CiSvg />} link="https://d1.ventures/" />
        <InvestorItem
          icon={<OKExBlockdreamSvg />}
          link="https://www.ouyi.cc/blockdream-ventures"
        />
        <InvestorItem
          icon={<HypersphereSvg />}
          link="https://hypersphere.ventures/"
        />
        <InvestorItem icon={<SNZHoldingSvg />} link="https://snzholding.com/" />
        <InvestorItem icon={<SevenXSvg />} link="http://www.7xvc.com/" />
        <InvestorItem icon={<DFGSvg />} link="https://www.dfg.group/" />
        <InvestorItem icon={<TRGCapitaSvg />} link="https://trgc.io/" />
        <InvestorItem icon={<PAKASvg />} link="https://www.paka.fund/" />
        <ItemWrapperEmpty />
        <ItemWrapperEmpty />
        <ItemWrapperEmpty />
        <ItemWrapperEmpty />
      </FrameWorkWrapper>
    </>
  )
}
