import React, { useState, useCallback, useRef, useEffect } from "react"
import styled from "styled-components"
import { breakpoints } from "../const"
import {
  DesktopSectionWrapper,
  DesktopSection,
} from "../Section/DesktopSection"
import FrameWork from "./Framework"
import { useIntl } from "gatsby-plugin-intl"
const BlogWrapper = styled.div``
const BlogTitle = styled.div`
  font-size: 64px;
  font-weight: bold;
  color: #ffffff;
  line-height: 77px;
  text-align: center;
  margin: 100px 0px;
  margin-top: 40px;
  @media screen and (max-width: ${breakpoints.md}px) {
    font-size: 32px;
  }
`

export default function (props) {
  const intl = useIntl()
  const locale = intl.locale
  useEffect(() => {
    // fetch(
    //   "https://api.rss2json.com/v1/api.json?rss_url=https:%2F%2Fmedium.com%2Ffeed%2Fzenlinkpro"
    // )
    //   .then(res => res.json())
    //   .then(json => {
    //     console.log(json)
    //     console.log("retainable-rss-embed", json)
    //   })
    // var container = document.getElementById("retainable-rss-embed");
    // console.log('retainable-rss-embed', container);
    // if (container) {
    //   console.log('retainable-rss-embed', container);
    //     var css = document.createElement('link');
    //     css.href = "https://www.twilik.com/assets/retainable/rss-embed/retainable.css";
    //     css.rel = "stylesheet"
    //     document.getElementsByTagName('head')[0].appendChild(css);
    //     var script = document.createElement('script');
    //     script.src = "https://www.twilik.com/assets/retainable/rss-embed/retainable.js";
    //     document.getElementsByTagName('body')[0].appendChild(script);
    // }
  }, [])
  return (
    locale === "en" && (
      <>
        <DesktopSectionWrapper
          id="blog"
          style={{
            background: `linear-gradient(90deg, #09072D 0%, #200F37 100%)`,
            paddingBottom: "100px",
            overflow: "hidden",
          }}
        >
          <DesktopSection>
            <BlogWrapper>
              <BlogTitle>{intl.formatMessage({ id: "Blog" })}</BlogTitle>
              {/* <div
              id="retainable-rss-embed"
              data-rss="https://medium.com/feed/zenlinkpro"
              data-maxcols="2"
              data-layout="grid"
              data-poststyle="inline"
              data-readmore="Read the rest"
              data-buttonclass="btn btn-primary"
              data-offset="-200"
            ></div> */}
              <FrameWork />
            </BlogWrapper>
          </DesktopSection>
        </DesktopSectionWrapper>
      </>
    )
  )
}
