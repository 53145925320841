import React, { useState, useCallback, useRef, useEffect } from "react"
import styled from "styled-components"
import {
  DesktopSectionWrapper,
  DesktopSection,
} from "../Section/DesktopSection"
import FrameWork from "./Framework"
import { useIntl } from "gatsby-plugin-intl"
import { breakpoints } from "../const"

const SectionWrapper = styled.div`
  margin-bottom: 160px;
  @media screen and (max-width: ${breakpoints.md}px) {
    margin-bottom: 70px;
  }
`
const Title = styled.div`
  font-size: 64px;
  font-weight: bold;
  color: #1c1f28;
  line-height: 77px;
  text-align: center;
  margin: 100px 0px;
  @media screen and (max-width: ${breakpoints.md}px) {
    font-size: 32px;
    margin-top: 70px;
    margin-bottom: 60px;
  }
`

export default function (props) {
  const intl = useIntl()
  return (
    <>
      <DesktopSectionWrapper
        id="partner"
        style={{
          background:
            "linear-gradient(45deg, #F0F6F9 12%, #F0F6F9 15%, #DFFFF2 25%, #D7EBF5 33%, #D6EBF4 41%, #E5F0FA 51%, #E4F0F9 56%, #F4E4F5 72%, #F9D5E7 98%, #F9D5E7 80%)",
        }}
      >
        <DesktopSection
          style={{
            padding: "0px",
          }}
        >
          <Title>{intl.formatMessage({ id: "Partners" })}</Title>
          <SectionWrapper>
            <FrameWork />
          </SectionWrapper>
        </DesktopSection>
      </DesktopSectionWrapper>
    </>
  )
}
