import React, { useState, useCallback, useRef, useEffect } from "react"
import styled from "styled-components"
import ZenlinkDexProtrolSvg from "./ZenlinkDEXProtocol.svg"
import PlugSvg from "./plug.svg"
const FrameWorkWrapper = styled.div`
  display: flex;
  justify-content: center;
`
const FrameWorkTitle = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: #11131a;
  line-height: 58px;
  background: linear-gradient(137deg, #f20082 0%, #9c00d5 50%, #3f58f0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const ItemWrapper = styled.div`
  margin: 0px 20px;
  width: 332px;
  height: 369px;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.04) 100%
  );
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.66);
  border-radius: 12px;
  border: 1px solid;
  border-color: rgba(255, 255, 255, 0.3);
  padding: 24px;
  /* border-image: linear-gradient(137deg, rgba(255, 255, 255, 0.3), rgba(151, 151, 151, 0.1)) 1 1; */
`

const ItemTitle = styled.div`
  font-size: 32px;
  font-weight: bold;
  color: #ffffff;
  line-height: 32px;
  background: linear-gradient(137deg, #f20082 0%, #9c00d5 50%, #3f58f0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
const ItemIcon = styled.div``
const ItemDivider = styled.div``
const ItemDesc = styled.div`
  width: 242px;
  height: 72px;
  font-size: 16px;
  color: #a4a8b3;
  line-height: 24px;
`

function ModuleItem(props) {
  const { icon, title, desc, bottom } = props
  return (
    <ItemWrapper>
      <ItemIcon>{icon}</ItemIcon>
      <ItemDivider />
      <ItemTitle>{title}</ItemTitle>
      <ItemDesc>{desc}</ItemDesc>
    </ItemWrapper>
  )
}

export default function (props) {
  return (
    <>
      <FrameWorkWrapper>
        <FrameWorkTitle>Zenlink DEX Module</FrameWorkTitle>
      </FrameWorkWrapper>
      <FrameWorkWrapper>
        <ModuleItem
          desc={
            "Plug the Zenlink DEX Module into the parachain code by way of a pallet."
          }
          icon={<PlugSvg />}
          title={"Plug and Play"}
        />
        <ModuleItem
          desc={
            "Replace the Zenlink DEX Module to upgrade to the latest version and benefit from more powerful features."
          }
          icon={<PlugSvg />}
          title={"Upgrade Flexibly"}
        />
        <ModuleItem
          desc={
            "Enables customizable combinations and interoperability with more DeFi modules, such as integrating with privacy modules to build privacy DEX."
          }
          icon={<PlugSvg />}
          title={"Composability and Interoperability"}
        />
      </FrameWorkWrapper>
    </>
  )
}
