import React, { useState, useCallback, useRef, useEffect } from "react"
import styled from "styled-components"
import ZenlinkDexProtrolSvg from "./ZenlinkDEXProtocol.svg"
import { breakpoints } from "../const"
import { useIntl } from "gatsby-plugin-intl"
import LeftSvg from "../Blog/Left.svg"
import RightSvg from "../Blog/right.svg"

const FrameWorkWrapper = styled.div`
  overflow: visible;
  display: flex;
  /* justify-content: center; */
  position: relative;
  transition: all 0.6s ease 0s;
  /* margin-bottom: 900px; */
`
const FrameWorkTitle = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: #11131a;
  line-height: 58px;
  background: linear-gradient(137deg, #f20082 0%, #9c00d5 50%, #3f58f0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const ItemWrapper = styled.div`
  position: relative;
  display: inline-block;
  z-index: 2;
  width: 300px;
  /* height: 250px; */
  border-radius: 28px;
  margin: 25px;
  padding: 5px;
  background: linear-gradient(135deg, #f20082 0%, #9c00d5 50%, #3f58f0 100%);
  @media screen and (max-width: ${breakpoints.md}px) {
    padding: 2px;
    width: 200px;
  }
  /* border-image: linear-gradient(137deg, rgba(255, 255, 255, 0.3), rgba(151, 151, 151, 0.1)) 1 1; */
`
const ItemInner = styled.div`
  width: 100%;
  height: 100%;
  background: #120f24;
  border-radius: 28px;
  padding: 20px;
  padding-bottom: 30px;
  /* border-image: linear-gradient(101deg, rgba(242, 0, 130, 1), rgba(156, 0, 213, 1), rgba(63, 88, 240, 1)) 5 5; */
  /* border-image: linear-gradient(137deg, rgba(255, 255, 255, 0.3), rgba(151, 151, 151, 0.1)) 1 1; */
`

const ItemTitle = styled.div`
  width: 242px;
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  line-height: 36px;
  padding-top: 10px;
  margin-bottom: 20px;
  @media screen and (max-width: ${breakpoints.md}px) {
    font-size: 14px;
    width: 122px;
    width: 200px;
  }
`
const ItemIcon = styled.div``
const ItemDivider = styled.div``

const ItemDescTitle = styled.div`
  font-size: 16px;
  line-height: 28px;
  font-weight: bold;
  color: #ffffff;
  ::before {
    /* content: "•"; */
    margin-right: 2px;
  }
  /* text-align: center; */
  @media screen and (max-width: ${breakpoints.md}px) {
    font-size: 12px;
  }
`
const ItemDesc = styled.div`
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
  ::before {
    content: "•";
    margin-right: 2px;
  }
  /* text-align: center; */
  @media screen and (max-width: ${breakpoints.md}px) {
    font-size: 12px;
  }
`
const TimelineBgWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 4800px;
  @media screen and (max-width: ${breakpoints.md}px) {
    width: 3500px;
  }
  z-index: 1;
`
const TimelineBg = styled.div`
  /* width: 100%; */
  width: 4200px;
  height: 50px;
  margin: 25px;
  background: linear-gradient(135deg, #f20082 0%, #9c00d5 50%, #3f58f0 100%);
  @media screen and (max-width: ${breakpoints.md}px) {
    height: 25px;
    width: 3300px;
  }
`

function FrameworkItem(props) {
  const { icon, title, desc, desc2, bottom } = props
  return (
    <ItemWrapper>
      <ItemInner>
        <ItemTitle>{title}</ItemTitle>
        <ItemDivider />
        <ItemDesc>{desc}</ItemDesc>
        <ItemDesc>{desc2}</ItemDesc>
      </ItemInner>
    </ItemWrapper>
  )
}
const SwitchButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  /* border-image: linear-gradient(135deg, rgba(242, 0, 130, 1), rgba(156, 0, 213, 1), rgba(63, 88, 240, 1)) 1 1; */
`
const SwitchButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid;
  border-color: #9c00d5;
  border-radius: 50%;
  margin: 20px 20px;
  color: #9c00d5;
  cursor: pointer;
  /* border-image: linear-gradient(135deg, rgba(242, 0, 130, 1), rgba(156, 0, 213, 1), rgba(63, 88, 240, 1)) 1 1; */
`

export default function (props) {
  const intl = useIntl()
  const frameWrapperRef = useRef()
  const [transedX, setTransedX] = useState(0)
  const lastRef = useRef()
  const [current, setCurrent] = useState(0)
  const handleStep = useCallback(
    step => {
      let roadMapWidth = frameWrapperRef.current.getBoundingClientRect
      let rect = lastRef.current.getBoundingClientRect()
      let bigRight = rect.x
      let tTramslateX = transedX + (rect.width + 40) * step
      if (step > 0) {
        tTramslateX =
          window.innerWidth > rect.x + rect.width + 40 ? transedX : tTramslateX // right: ;
      } else {
        tTramslateX = tTramslateX < 0 ? 0 : tTramslateX // left
      }
      setTransedX(tTramslateX)
      frameWrapperRef.current.style.transform = `translateX(${-tTramslateX}px)`
      // console.log("roadMapWidth", rect)
    },
    [transedX]
  )
  return (
    <>
      <FrameWorkWrapper ref={frameWrapperRef}>
        <TimelineBgWrapper>
          <TimelineBg />
        </TimelineBgWrapper>
        <ItemWrapper>
          <ItemInner>
            <ItemTitle>{"2023 Q4"}</ItemTitle>
            <ItemDivider />
            <ItemDescTitle>
              {intl.formatMessage({
                id: "Eco-Incubation",
              })}
            </ItemDescTitle>
            <ItemDesc>
              {intl.formatMessage({
                id: "Launch the Zenlink Incubator to support talented teams that build around Web3 infrastructure",
              })}
              {}
            </ItemDesc>
          </ItemInner>
        </ItemWrapper>
        <ItemWrapper>
          <ItemInner>
            <ItemTitle>{"2023 Q3"}</ItemTitle>
            <ItemDivider />
            <ItemDescTitle>
              {intl.formatMessage({
                id: "Full-chain liquidity aggregation phase 2",
              })}
            </ItemDescTitle>
            <ItemDesc>
              {intl.formatMessage({
                id: "Optimized protocol with gas consumption, order splitting, upgrade to Zenlink Mega Aggregator v2",
              })}
              {}
            </ItemDesc>
            <ItemDesc>
              {intl.formatMessage({
                id: "Enable the Governance module and combine it to boost Zenlink Mega Aggregator usage",
              })}
              {}
            </ItemDesc>
            <ItemDesc>
              {intl.formatMessage({
                id: "Combining XCM's latest advances to attempt Parachain liquidity aggregation within the Polkadot ecosystem",
              })}
              {}
            </ItemDesc>
          </ItemInner>
        </ItemWrapper>
        <ItemWrapper>
          <ItemInner>
            <ItemTitle>{"2023 Q2"}</ItemTitle>
            <ItemDivider />
            <ItemDescTitle>
              {intl.formatMessage({
                id: "Full-chain liquidity aggregation phase 1",
              })}
            </ItemDescTitle>
            <ItemDesc>
              {intl.formatMessage({
                id: "Finish prototyping Zenlink Mega Aggregator v1",
              })}
              {}
            </ItemDesc>
            <ItemDesc>
              {intl.formatMessage({
                id: "Integrating aggregator functionality into Zenlink DEX",
              })}
              {}
            </ItemDesc>
            <ItemDesc>
              {intl.formatMessage({
                id: "Enable new Zenlink DEX DApp main site and complete migration",
              })}
              {}
            </ItemDesc>

            <ItemDesc>
              {intl.formatMessage({
                id: "Enable Zenlink DEX DApp on Mobile",
              })}
              {}
            </ItemDesc>
          </ItemInner>
        </ItemWrapper>
        <ItemWrapper>
          <ItemInner>
            <ItemTitle>{"2023 Q1"}</ItemTitle>
            <ItemDivider />
            <ItemDescTitle>
              {intl.formatMessage({
                id: "Chain-driven decentralized deployment solutions",
              })}
            </ItemDescTitle>
            <ItemDesc>
              {intl.formatMessage({
                id: "Finish Zenlink Deployment Kits development",
              })}
            </ItemDesc>
            <ItemDesc>
              {intl.formatMessage({
                id: "Integrate more Parachain and use the new deployment paradigm to complete the integration of Zenlink DEX with Parachain",
              })}
            </ItemDesc>
          </ItemInner>
        </ItemWrapper>

        <ItemWrapper>
          <ItemInner>
            <ItemTitle>{"2022 Q4"}</ItemTitle>
            <ItemDivider />
            <ItemDescTitle>
              {intl.formatMessage({
                id: "Bringing composability and interoperability with more DeFi Modules",
              })}
            </ItemDescTitle>
            <ItemDesc>
              {intl.formatMessage({
                id: "Build at least one structured product",
              })}
              {}
            </ItemDesc>
            <ItemDesc>
              {intl.formatMessage({
                id: "Implement compositions with other DeFi modules",
              })}
              {}
            </ItemDesc>
          </ItemInner>
        </ItemWrapper>

        <ItemWrapper>
          <ItemInner>
            <ItemTitle>{"2022 Q3"}</ItemTitle>
            <ItemDivider />
            <ItemDescTitle>
              {intl.formatMessage({
                id: "Upgrade network infrastructure to enhance user experience",
              })}
            </ItemDescTitle>
            <ItemDesc>
              {intl.formatMessage({ id: "Enable Zenlink DEX DApp on Mobile" })}
              {}
            </ItemDesc>
            <ItemDesc>
              {intl.formatMessage({
                id: "Achieve X-Transfer v2",
              })}
              {}
            </ItemDesc>
            <ItemDesc>
              {intl.formatMessage({ id: "Integrating More Parachains" })}
              {}
            </ItemDesc>
          </ItemInner>
        </ItemWrapper>

        <ItemWrapper>
          <ItemInner>
            <ItemTitle>{"2022 Q2"}</ItemTitle>
            <ItemDivider />
            <ItemDescTitle>
              {intl.formatMessage({
                id: "Build ZLK Matrix",
              })}
            </ItemDescTitle>
            <ItemDesc>
              {intl.formatMessage({
                id: "Launch ZLK Governance v1",
              })}
              {}
            </ItemDesc>
            <ItemDesc>
              {intl.formatMessage({ id: "Launch Farming v3" })}
              {}
            </ItemDesc>
            <ItemDesc>
              {intl.formatMessage({ id: "Launch Zenlink DAO NFT" })}
              {}
            </ItemDesc>
          </ItemInner>
        </ItemWrapper>

        <ItemWrapper>
          <ItemInner>
            <ItemTitle>{"2022 Q1"}</ItemTitle>
            <ItemDivider />
            <ItemDescTitle>
              {intl.formatMessage({
                id: "Build Zenlink DexConnect v1",
              })}
            </ItemDescTitle>
            <ItemDesc>
              {intl.formatMessage({
                id: "Achieve Hybrid AMM",
              })}
            </ItemDesc>
            <ItemDesc>
              {intl.formatMessage({ id: "Achieve Zenlink DEX SDK v1" })}
              {}
            </ItemDesc>
          </ItemInner>
        </ItemWrapper>

        <ItemWrapper>
          <ItemInner>
            <ItemTitle>{"2021 Q4"}</ItemTitle>
            <ItemDivider />
            <ItemDesc>
              {intl.formatMessage({ id: "Launch Zenlink on Polkadot" })}
              {}
            </ItemDesc>
            <ItemDesc>
              {intl.formatMessage({ id: "Open Resource" })}
              {}
            </ItemDesc>
            <ItemDesc>
              {intl.formatMessage({
                id: "Start DEX Composable Between Each Parachains",
              })}
              {}
            </ItemDesc>
            <ItemDesc>
              {intl.formatMessage({ id: "Start Zenlink Builder Program" })}
              {}
            </ItemDesc>
          </ItemInner>
        </ItemWrapper>

        <ItemWrapper>
          <ItemInner>
            <ItemTitle>{"2021 Q3"}</ItemTitle>
            <ItemDivider />
            <ItemDesc>
              {intl.formatMessage({ id: "Achieve Zenlink DEX DApp" })}
              {}
            </ItemDesc>
            <ItemDesc>
              {intl.formatMessage({ id: "Launch 2nd Public Test" })}
              {}
            </ItemDesc>
            <ItemDesc>
              {intl.formatMessage({
                id: "Launch Zenlink on Kusama (integrate to Plasm / Bifrost / Moonbeam)",
              })}
              {}
            </ItemDesc>
            <ItemDesc>
              {intl.formatMessage({ id: "Tokenomic Lightpaper Release" })}
              {}
            </ItemDesc>
          </ItemInner>
        </ItemWrapper>

        <ItemWrapper>
          <ItemInner>
            <ItemTitle>{"2021 Q2"}</ItemTitle>
            <ItemDivider />
            <ItemDesc>
              {intl.formatMessage({
                id: "Launch 1st Public Test (with 4 parachains)",
              })}
              {}
            </ItemDesc>
            <ItemDesc>
              {intl.formatMessage({ id: "Launch Zenlink SlotVault" })}
              {}
            </ItemDesc>
            <ItemDesc>
              {intl.formatMessage({
                id: "Achieve Zenlink DEX Aggregator&Smart Order Routing SDK",
              })}
              {}
            </ItemDesc>
          </ItemInner>
        </ItemWrapper>

        <ItemWrapper>
          <ItemInner>
            <ItemTitle>{"2021 Q1"}</ItemTitle>
            <ItemDivider />
            <ItemDesc>
              {intl.formatMessage({ id: "Test on Rococo (only 1 parachain)" })}
              {}
            </ItemDesc>
            <ItemDesc>
              {intl.formatMessage({
                id: "Build Zenlink DEX Aggregator Prototype",
              })}
              {}
            </ItemDesc>
            <ItemDesc>
              {intl.formatMessage({ id: "Complete 1st Cross-chain DEX Test" })}
              {}
            </ItemDesc>
          </ItemInner>
        </ItemWrapper>

        <ItemWrapper>
          <ItemInner>
            <ItemTitle>{"2020 Q4"}</ItemTitle>
            <ItemDivider />
            <ItemDesc>
              {intl.formatMessage({
                id: "Achieve three implementations(Module / WASM / EVM)",
              })}
              {}
            </ItemDesc>
            <ItemDesc>
              {intl.formatMessage({ id: "Deliver two Web3 Grants" })}
              {}
            </ItemDesc>
          </ItemInner>
        </ItemWrapper>

        <ItemWrapper ref={lastRef}>
          <ItemInner>
            <ItemTitle>{"2020 Q3"}</ItemTitle>
            <ItemDivider />
            <ItemDesc>
              {intl.formatMessage({
                id: "Build Zenlink DEX Procotol Prototype",
              })}
            </ItemDesc>
            <ItemDesc>
              {intl.formatMessage({ id: "Publish Zenlink Whitepaper v0.1" })}
              {}
            </ItemDesc>
          </ItemInner>
        </ItemWrapper>
      </FrameWorkWrapper>
      <SwitchButtonWrapper>
        <SwitchButton
          onClick={() => {
            handleStep(-1)
          }}
        >
          <RightSvg />
        </SwitchButton>
        <SwitchButton
          onClick={() => {
            handleStep(1)
          }}
        >
          <LeftSvg />
        </SwitchButton>
      </SwitchButtonWrapper>
    </>
  )
}
