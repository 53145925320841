import React, { useState, useCallback, useRef, useEffect } from "react"
import styled from "styled-components"
import ZenlinkDexProtrolSvg from "./ZenlinkDEXProtocol.svg"
import { breakpoints } from "../const"
import hoverBg from "./hoverBg.png"
import normalBg from "./normalBg.png"
import { useIntl } from "gatsby-plugin-intl"

const FrameWorkWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 150px;
  @media screen and (max-width: ${breakpoints.md}px) {
    margin-bottom: 70px;
  }
`
const FrameWorkTitle = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: #11131a;
  line-height: 58px;
  background: linear-gradient(137deg, #f20082 0%, #9c00d5 50%, #3f58f0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const ItemWrapper = styled.div`
  position: relative;
  width: 30%;
  height: 317px;
  padding: 4px;
  border-radius: 20px;
  margin: 20px auto;
  :hover {
    background: linear-gradient(
      135deg,
      rgba(242, 0, 130, 1),
      rgba(156, 0, 213, 1),
      rgba(63, 88, 240, 1)
    );
    border-radius: 20px;
  }
  @media screen and (max-width: ${breakpoints.md}px) {
    width: 90%;
    height: 257px;
  }
  padding: 1px;
  /* border-image: linear-gradient(137deg, rgba(255, 255, 255, 0.3), rgba(151, 151, 151, 0.1)) 1 1; */
`
const ItemInner = styled.div`
  position: relative;
  padding: 24px;
  width: 100%;
  height: 100%;
  background: #120f24;
  border-radius: 28px;
  background: linear-gradient(
    179deg,
    rgba(242, 0, 130, 0.17) 0%,
    rgba(63, 88, 240, 0) 100%
  );
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.66);
  border-radius: 20px;
  :hover {
    background: #0a1024;
  }
  z-index: 100;
  /* border-image: linear-gradient(101deg, rgba(242, 0, 130, 1), rgba(156, 0, 213, 1), rgba(63, 88, 240, 1)) 5 5; */
  /* border-image: linear-gradient(137deg, rgba(255, 255, 255, 0.3), rgba(151, 151, 151, 0.1)) 1 1; */
`

const ItemTitle = styled.div`
  height: 32px;
  font-size: 32px;
  margin-top: 24px;
  font-weight: bold;
  color: #ffffff;
  line-height: 32px;
  @media screen and (max-width: ${breakpoints.md}px) {
    font-size: 16px;
  }
`
const ItemIcon = styled.div``
const ItemDivider = styled.div`
  width: 44px;
  height: 1px;
  margin-top: 24px;
  margin-bottom: 24px;
  border: 1px solid rgba(255, 255, 255, 0.5);
`
const ItemDesc = styled.div`
  position: relative;
  height: 140px;
  font-size: 16px;
  color: #d0d2d9;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 100;
  @media screen and (max-width: ${breakpoints.md}px) {
    font-size: 12px;
  }
`
const BgImgBtttom = styled.div`
position: absolute;
width: 100%;
height: 67px;
left: 0;
bottom: 0;
border-bottom-right-radius: 20px;
border-bottom-left-radius: 20px;
background-image: url('${normalBg}');
background-size: cover;
${ItemWrapper}:hover & {
    background-image: url('${hoverBg}');
  }
`

function FrameworkItem(props) {
  const { icon, title, desc, bottom } = props
  return (
    <ItemWrapper>
      <ItemInner>
        <BgImgBtttom src={hoverBg} />
        <ItemTitle>{title}</ItemTitle>
        <ItemDivider />
        <ItemDesc>{desc}</ItemDesc>
      </ItemInner>
    </ItemWrapper>
  )
}

export default function (props) {
  const intl = useIntl()
  return (
    <>
      <FrameWorkWrapper>
        <FrameworkItem
          desc={intl.formatMessage({
            id:
              "Deploy Zenlink DEX Module, WASM and EVM into parachains with one click and interconnect them",
          })}
          title={intl.formatMessage({ id: "Step 1" })}
        />
        <FrameworkItem
          desc={intl.formatMessage({
            id:
              "Find the best trading path through all parachain liquidity sources with Zenlink Smart Order Routing and aggregate other DEX liquidity with Zenlink DEX Aggregator",
          })}
          title={intl.formatMessage({ id: "Step 2" })}
        />
        <FrameworkItem
          desc={intl.formatMessage({
            id:
              "Users can easily complete efficient, well-priced, and low-slippage swap with Zenlink DEX",
          })}
          title={intl.formatMessage({ id: "Step 3" })}
        />
      </FrameWorkWrapper>
    </>
  )
}
