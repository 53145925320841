import React, { useState, useCallback, useRef, useEffect } from "react"
import styled from "styled-components"
import ZenlinkDexProtrolSvg from "./ZenlinkDEXProtocol.svg"
import AcalaSvg from "./acala.svg"
import ChinaxSvg from "./chainx.svg"
import BifrostSvg from "./bifrost.svg"
import StafiSvg from "./stafi.svg"
import Web3FundationSvg from "./web3fundation.svg"
import ChainlinkSvg from "./chainlink.svg"
import AstarSvg from "./Astar.svg"
import PhalaNetworkSvg from "./PhalaNetwork.svg"
import BondlySvg from "./Bondly.svg"
import PatractSvg from "./Patract.svg"
import DARWINIASvg from "./DARWINIA.svg"
import OpenoceanSvg from "./openocean.svg"
import MathchainSvg from "./Mathchain.svg"
import MantaSvg from "./manta.svg"
import SlowmistSvg from "./Slowmist.svg"
import MoonbeamSvg from "./Moonbeam.svg"
import GearSvg from "./Gear.svg"
import ImmunefiSvg from "./Immunefi.svg"
import InsurAceSvg from "./InsurAce.svg"
import MultichainSvg from "./Multichain.svg"
import CelerSvg from "./Celer.svg"
import NomadSvg from "./Nomad.svg"
import IllusionistGroupSvg from "./IllusionistGroup.svg"
import GateSvg from "./Gate.svg"

import { breakpoints } from "../const"

const FrameWorkWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 20px;
  @media screen and (max-width: ${breakpoints.md}px) {
    padding: 0 10px;
  }
`
const FrameWorkTitle = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: #11131a;
  line-height: 58px;
  background: linear-gradient(137deg, #f20082 0%, #9c00d5 50%, #3f58f0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const ItemWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18%;
  width: 240px;
  height: 90px;
  background: #ffffff;
  border-radius: 8px;
  margin: 10px 10px;
  @media screen and (max-width: ${breakpoints.xl}px) {
    /* text-align: center; */
    width: 30%;
    width: 240px;
  }
  /* border-image: linear-gradient(137deg, rgba(255, 255, 255, 0.3), rgba(151, 151, 151, 0.1)) 1 1; */
  @media screen and (max-width: ${breakpoints.md}px) {
    /* text-align: center; */
    width: 40%;
    flex-grow: 1;
    /* width: 154px; */
    height: 76px;
  }
  :hover {
    background: linear-gradient(136deg, #fcf0ff 0%, #ffedf5 26%, #eaf8ff 100%);
    box-shadow: 0px 1px 1px 0px rgba(255, 255, 255, 0.77);
    border-radius: 12px;
    border: 2px solid;
    border-image: linear-gradient(
        180deg,
        rgba(255, 245, 245, 1),
        rgba(255, 255, 255, 0.41)
      )
      2 2;
    z-index: 2;
  }
`
const ItemWrapperEmpty = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18%;
  width: 240px;
  margin: 10px 10px;

  @media screen and (max-width: ${breakpoints.xl}px) {
    /* text-align: center; */
    width: 30%;
    width: 240px;
  }
  /* border-image: linear-gradient(137deg, rgba(255, 255, 255, 0.3), rgba(151, 151, 151, 0.1)) 1 1; */
  @media screen and (max-width: ${breakpoints.md}px) {
    /* text-align: center; */
    width: 40%;
    flex-grow: 1;
    /* width: 154px; */
  }
  /* border-image: linear-gradient(137deg, rgba(255, 255, 255, 0.3), rgba(151, 151, 151, 0.1)) 1 1; */
`

const ItemTitle = styled.div`
  width: 242px;
  height: 72px;
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  line-height: 36px;
`
const ItemIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100%;
  height: 100%; */
  padding: 20px 20px;
  @media screen and (max-width: ${breakpoints.xl}px) {
    /* text-align: center; */
    /* width: 30%; */
  }
`
const ItemDivider = styled.div``
const ItemDesc = styled.div`
  width: 242px;
  height: 72px;
  font-size: 16px;
  color: #a4a8b3;
  line-height: 24px;
`
const ItemHoverBg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(136deg, #fcf0ff 0%, #ffedf5 26%, #eaf8ff 100%);
  box-shadow: 0px 1px 1px 0px rgba(255, 255, 255, 0.77);
  border-radius: 12px;
  border: 2px solid;
  border-image: linear-gradient(
      180deg,
      rgba(255, 245, 245, 1),
      rgba(255, 255, 255, 0.41)
    )
    2 2;
  z-index: 2;
`
const ItemInner = styled.div`
  position: relative;
  width: 100%;
  /* width: 240px; */
  height: 100%;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${breakpoints.md}px) {
    /* text-align: center; */
    /* width: 45%;
    width: 154px;
    height: 76px; */
  }
`
const ItemInnerLink = styled.a`
  /* width: 240px; */
  width: 100%;
  height: 100%;
`

function PartnerItem(props) {
  const { icon, title, desc, bottom, link } = props
  return (
    <ItemWrapper>
      {/* <ItemHoverBg/> */}
      <ItemInnerLink href={link} target="_blank">
        <ItemInner>
          <ItemIcon>{icon}</ItemIcon>
        </ItemInner>
      </ItemInnerLink>
    </ItemWrapper>
  )
}
export default function (props) {
  return (
    <>
      <FrameWorkWrapper>
        <PartnerItem icon={<MoonbeamSvg />} link="https://moonbeam.network" />
        <PartnerItem icon={<GearSvg />} link="https://www.gear-tech.io" />
        <PartnerItem icon={<AcalaSvg />} link="https://acala.network" />
        <PartnerItem icon={<BifrostSvg />} link="https://bifrost.finance/" />
        <PartnerItem icon={<AstarSvg />} link="https://astar.network/" />

        <PartnerItem
          icon={<Web3FundationSvg />}
          link="https://web3.foundation/"
        />
        <PartnerItem icon={<ChainlinkSvg />} link="https://chain.link/" />
        <PartnerItem
          icon={<SlowmistSvg />}
          link="https://www.slowmist.com/en"
        />
        <PartnerItem icon={<ImmunefiSvg />} link="https://immunefi.com" />
        <PartnerItem icon={<InsurAceSvg />} link="https://www.insurace.io" />

        <PartnerItem icon={<PhalaNetworkSvg />} link="https://phala.network/" />
        <PartnerItem icon={<MantaSvg />} link="https://manta.network/" />
        <PartnerItem icon={<PatractSvg />} link="https://patract.io/" />
        <PartnerItem icon={<DARWINIASvg />} link="https://darwinia.network/" />
        <PartnerItem icon={<MathchainSvg />} link="https://mathchain.org/cn/" />

        <PartnerItem icon={<StafiSvg />} link="https://www.stafi.io/" />
        <PartnerItem icon={<ChinaxSvg />} link="https://www.chainx.org" />
        <PartnerItem
          icon={<OpenoceanSvg />}
          link="https://openocean.finance/"
        />
        <PartnerItem icon={<MultichainSvg />} link="https://multichain.org" />
        <PartnerItem icon={<CelerSvg />} link="https://www.celer.network" />

        <PartnerItem icon={<NomadSvg />} link="https://app.nomad.xyz" />
        <PartnerItem
          icon={<IllusionistGroupSvg />}
          link="https://illusionistgroup.com"
        />
        <PartnerItem icon={<BondlySvg />} link="https://www.bondly.finance/" />
        <PartnerItem icon={<GateSvg />} link="https://www.gate.io" />

        <ItemWrapperEmpty />
        <ItemWrapperEmpty />
        <ItemWrapperEmpty />
        <ItemWrapperEmpty />
      </FrameWorkWrapper>
    </>
  )
}
