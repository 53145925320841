import React, { useState, useCallback, useRef, useEffect } from "react"
import styled from "styled-components"
import DeployDexOne from "./DeployDexOne.svg"
import AggregatorSvg from "./Aggregator.svg"
import { breakpoints } from "../const"
import { useIntl } from "gatsby-plugin-intl"
import OneClickConnection from "./OneClickConnection.png"
import OneClickDeploy from "./OneClickDeploy.png"
import StrictLine from "./StrictLine.svg"

const FrameWorkWrapper = styled.div`
  display: flex;
  /* justify-content: center; */
  margin-top: 100px;
  @media screen and (max-width: ${breakpoints.md}px) {
    margin-top: 10px;
  }
`
const FrameWorkTitle = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: #11131a;
  line-height: 58px;
  background: linear-gradient(137deg, #f20082 0%, #9c00d5 50%, #3f58f0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const ItemWrapper = styled.div`
  margin: 0px 20px;
  width: 290px;
  height: 369px;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.04) 100%
  );
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.66);
  border-radius: 12px;
  border: 1px solid;
  border-color: rgba(255, 255, 255, 0.3);
  padding: 24px;
  /* border-image: linear-gradient(137deg, rgba(255, 255, 255, 0.3), rgba(151, 151, 151, 0.1)) 1 1; */
`

const ItemTitle = styled.div`
  width: 242px;
  height: 72px;
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  line-height: 36px;
`
const ItemIcon = styled.div``
const ItemDivider = styled.div``
const ItemDesc = styled.div`
  width: 242px;
  height: 72px;
  font-size: 16px;
  color: #a4a8b3;
  line-height: 24px;
`

const SubTitle = styled.div`
  position: relative;
  font-size: 48px;
  font-weight: bold;
  color: #11131a;
  line-height: 58px;
  background: linear-gradient(137deg, #f20082 0%, #9c00d5 50%, #3f58f0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 100px;
  @media screen and (max-width: ${breakpoints.md}px) {
    font-size: 32px;
    margin-bottom: 40px;
  }
`
const TimeLineWrapper = styled.div`
  margin-right: 25px;
  margin-left: 30px;
  margin-top: 20px;
  border-left: 1px solid rgba(155, 155, 155, 0.3);
  @media screen and (max-width: ${breakpoints.md}px) {
    margin-left: 0px;
    /* text-align: center; */
    /* margin-right: 10px; */
  }
`
const ContentWrapper = styled.div`
  flex-grow: 1;
`
const ContentItemWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 60px 0px;
  margin-bottom: 140px;
  @media screen and (max-width: ${breakpoints.md}px) {
    /* text-align: center; */
    flex-direction: column;
    margin-bottom: 50px;
  }
`
const ContentInfoWrapper = styled.div`
  max-width: 535px;
  margin-left: 20px;
  margin-right: 20px;
  @media screen and (max-width: ${breakpoints.md}px) {
    /* text-align: center; */
    order: -2;
  }
`
const ContentInfoTitle = styled.div`
  font-size: 32px;
  font-weight: bold;
  color: #ffffff;
  line-height: 39px;
  margin-bottom: 30px;
  @media screen and (max-width: ${breakpoints.md}px) {
    font-size: 24px;
    margin-bottom: 20px;
  }
`
const ContentInfoDesc = styled.div`
  font-size: 18px;
  color: rgba(255, 255, 255, 0.7);
  line-height: 27px;
  @media screen and (max-width: ${breakpoints.md}px) {
    font-size: 14px;
  }
`
const ImageWrapper = styled.div`
  width: 360px;
  max-width: 360px;
  max-height: 360px;
  height: 360px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 30px 0px;
  @media screen and (max-width: ${breakpoints.md}px) {
    width: 280px;
    height: 280px;
  }
`
const CorlorCircle = styled.div`
  position: absolute;
  left: -35px;
  top: 15px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #f20082;
  border: 1px solid #feb64e;
`
const GreenCircle = styled.div`
  position: absolute;
  left: -31px;
  top: 165px;
  border-radius: 50%;
  width: 11px;
  height: 11px;
  background: #4fceac;
  border: 5px solid rgba(79, 206, 172, 0.25);
  @media screen and (max-width: ${breakpoints.md}px) {
    top: 15px;
  }
`

function FrameworkItem(props) {
  const { icon, title, desc, bottom } = props
  return (
    <ItemWrapper>
      <ItemIcon>{icon}</ItemIcon>
      <ItemDivider />
      <ItemTitle>{title}</ItemTitle>
      <ItemDesc>{desc}</ItemDesc>
    </ItemWrapper>
  )
}

export default function (props) {
  const intl = useIntl()
  return (
    <>
      <FrameWorkWrapper>
        <TimeLineWrapper>{/* <StrictLine /> */}</TimeLineWrapper>
        <ContentWrapper>
          <SubTitle>
            <CorlorCircle />
            For Developers
          </SubTitle>
          <ContentItemWrapper>
            <GreenCircle />
            <ImageWrapper
              style={{
                backgroundImage: `url(${OneClickDeploy})`,
              }}
            />
            <ContentInfoWrapper>
              <ContentInfoTitle>
                {intl.formatMessage({ id: "One-click to Deploy DEX" })}
              </ContentInfoTitle>
              <ContentInfoDesc>
                {intl.formatMessage({
                  id:
                    "By accessing the Zenlink DEX Protocol, parachains can deploy DEX in one-click without repeated development",
                })}
              </ContentInfoDesc>
            </ContentInfoWrapper>
          </ContentItemWrapper>
          <ContentItemWrapper>
            <GreenCircle />
            <ContentInfoWrapper>
              <ContentInfoTitle>
                {intl.formatMessage({
                  id: "One-Click Connection to Zenlink DEX Aggregator",
                })}
              </ContentInfoTitle>
              <ContentInfoDesc>
                {intl.formatMessage({
                  id:
                    "All DEX DApps on Polkadot can easily connect to the Zenlink DEX Aggregator and share the benefits of the Zenlink network",
                })}
              </ContentInfoDesc>
            </ContentInfoWrapper>
            <ImageWrapper
              style={{
                backgroundImage: `url(${OneClickConnection})`,
              }}
            />
          </ContentItemWrapper>
        </ContentWrapper>
      </FrameWorkWrapper>
    </>
  )
}
