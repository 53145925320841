import React, { useState } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { BaseInner } from "../baseComponents"
import { useIntl } from "gatsby-plugin-intl"
import HeaderBannerSvg from "../../images/v2/HeaderBanner.svg"
import HomePicSvg from "./homepic.svg"
import HomePicPng from "./homepic.png"
import BgDesktop from "./bgdesktop.png"
import BgMobile from "./bgmobile.png"
import BgMain from "./mobile/picmain.png"
import BgLeft from "./mobile/picleft.png"
import BgTop from "./mobile/pictop.png"

// import Media from "react-media"
import {
  DesktopSectionWrapper,
  DesktopSection,
} from "../Section/DesktopSection"
import LanchSvg from "./lanch.svg"
import Lanch2Svg from "./lanch2.svg"
import LinkSvg from "./link.svg"
import Header from "../Header"
import "./index.css"
import { breakpoints } from "../const"

const StyledSection = styled.section`
  // display: flex;
  background-image: linear-gradient(90deg, #09072d 0%, #200f37 100%);
  // justify-content: space-around;
  // padding: 80px 0;
`

export const InnerSection = styled(BaseInner)`
  align-items: center;
  @media screen and (max-width: 750px) {
    flex-direction: column-reverse;
    align-items: center;
    & > div:first-of-type {
      padding-bottom: 1px;
    }
  }
`

const Detail = styled.div`
  p {
    span {
      color: #ffffff;
      font-size: 24px;
    }
    margin-top: 50px;
    font-size: 16px;
    color: #ffffff;
    line-height: 32px;
    max-width: 528px;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 750px) {
    display: flex;
    flex-direction: column;
    max-width: 375px;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 450px) {
    display: flex;
    flex-direction: column;
    max-width: 375px;
    padding: 10px 20px;
  }
`

const Title = styled.h2`
  font-size: 40px;
  color: #ffffff;
  line-height: 60px;
  max-width: 460px;
  margin: 0;

  @media screen and (max-width: 750px) {
    width: 375px;
    padding: 0px 0px;
    margin-top: 20px;
  }

  @media screen and (max-width: 450px) {
    width: 375px;
    padding: 0px 20px;
    margin-top: 20px;
  }
`

const HeaderBannerInner = styled.div`
  position: absolute;
  width: 100%;
  padding-left: 60px;
  /* display: flex; */
  /* justify-content: center; */
  /* transition: all 300ms ease 0s;
  transform: translateX(1800px); */
  top: 0;
  left: 0;
  @media screen and (max-width: ${breakpoints.md}px) {
    padding-left: 0px;
  }
  /* left: -1800px; */
`
const BannerTitleWrap = styled.div`
  margin-top: 240px;
  font-size: 56px;
  font-weight: bold;
  @media screen and (max-width: ${breakpoints.md}px) {
    font-size: 36px;
    margin-top: 120px;
  }
  /* width: 892px; */
`
const BannerTitleText = styled.div`
  max-width: 780px;
  font-weight: 850;
  line-height: 1.2;
  text-align: left;
  color: #ffffff;
  @media screen and (max-width: ${breakpoints.md}px) {
    text-align: center;
  }
`
const BannerTitleCompos = styled.span`
  color: #ffffff;
  /* background: linear-gradient(135deg, #f20082 0%, #9c00d5 50%, #3f58f0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  /* margin-left: 20px;
  margin-right: 20px; */
  @media screen and (max-width: ${breakpoints.md}px) {
    display: inline-block;
  }
`
const BannerDesc = styled.div`
  margin-top: 60px;
  color: #ffffff;
  max-width: 732px;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.6);
  line-height: 28px;
  @media screen and (max-width: ${breakpoints.md}px) {
    text-align: center;
    font-size: 14px;
  }
`

const BannerAction = styled.div`
  display: flex;
  margin-top: 60px;
  color: #ffffff;
  /* width: 732px; */
  @media screen and (max-width: ${breakpoints.md}px) {
    justify-content: space-around;
  }
`

const BannerActionItem = styled.span`
  display: flex;
  align-items: center;
  margin-right: 60px;
  color: #ffffff;
  cursor: pointer;
  padding-bottom: 12px;
  border-width: 0px;
  border-bottom: 1px solid transparent;
  :hover {
    border-bottom: 1px solid;
    border-image: linear-gradient(
        101deg,
        rgba(242, 0, 130, 1),
        rgba(156, 0, 213, 1),
        rgba(63, 88, 240, 1)
      )
      5 5;
  }
  @media screen and (max-width: ${breakpoints.md}px) {
    margin-left: 10px;
    margin-right: 0px;
  }
`
const BannerActionItemIconWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`
const BannerActionItemSvgActive = styled.span`
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  display: none;
  ${BannerActionItem}:hover & {
    display: flex;
  }
`
const BannerActionItemSvgNormal = styled.span`
  display: flex;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  ${BannerActionItem}:hover & {
    display: none;
  }
`
const BannerActionItemText = styled.span`
  font-size: 24px;
  font-weight: 800;
  margin-right: 6px;
  @media screen and (max-width: ${breakpoints.md}px) {
    font-size: 20px;
  }
`
const HeaderBannerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 989px;
  @media screen and (max-width: ${breakpoints.md}px) {
    height: 950px;
  }
`

const HeaderBannerImg = styled.div`
  /* max-width: 1280px; */
  position: absolute;
  display: flex;
  justify-content: center;
  overflow: hidden;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 1920px;
  height: 1200px;
  /* background-image: url(${BgDesktop}); */
  @media screen and (max-width: ${breakpoints.xl}px) {
    justify-content: center;
    /* background-image: url(${BgDesktop}); */
  }
  @media screen and (max-width: ${breakpoints.md}px) {
    display: none;
  }
`
const HeaderBannerImgInner = styled.img`
  width: 1920px;
  height: 1119px;
  /* height: 100%; */
  /* background-size: contain;
  background-position: center;
  background-repeat: no-repeat; */
  /* max-width: 1280px; */
  /* background-image: url(${HomePicPng}); */
`
const HeaderBannerImgWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  height: 100%;
  display: none;
  @media screen and (max-width: ${breakpoints.md}px) {
    display: block;
  }
`
const HeaderBannerImgMain = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  /* width: 300px; */
  width: 100%;

  overflow: hidden;
  height: 350px;
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  background-image: url(${BgMain});
`
const HeaderBannerImgLeft = styled.div`
  position: absolute;
  top: 40%;
  left: -200px;
  display: flex;
  justify-content: center;
  width: 300px;
  overflow: hidden;
  height: 300px;
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  background-image: url(${BgLeft});
`
const HeaderBannerImgTop = styled.div`
  position: absolute;
  top: -100px;
  left: 0px;
  display: flex;
  justify-content: center;
  width: 300px;
  overflow: hidden;
  height: 300px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  background-image: url(${BgTop});
`

// const Line = styled.hr`
//   display: inline-flex;
//   width: 80px;
//   height: 8px;
//   background: #2997ec;
//   border: 0;
//   margin: 40px 0 0;
// `

// const ItemImgWrap = styled.div`
//   display: flex;
//   width: 100%;
//   height: 100$;
//   padding: 5px;
// `

export function BannerBgImage() {
  return (
    <HeaderBannerImgWrapper>
      <HeaderBannerImgTop />
      <HeaderBannerImgLeft />
      <HeaderBannerImgMain />
    </HeaderBannerImgWrapper>
  )
}

export default function (props) {
  const data = useStaticQuery(graphql`
    query {
      bannerImage: file(relativePath: { eq: "banner.png" }) {
        childImageSharp {
          fixed(width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      headerSmallImage: file(relativePath: { eq: "headersmall.png" }) {
        childImageSharp {
          fixed(width: 130) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      polkadotImage: file(relativePath: { eq: "polkadot.png" }) {
        childImageSharp {
          fixed(width: 160) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      whitePaper: file(relativePath: { eq: "whitepaper.png" }) {
        childImageSharp {
          fixed(width: 160) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const intl = useIntl()
  const fetchLocation = value => {
    window.location.href = value
  }
  const isBrowser = typeof window !== `undefined`
  return (
    <DesktopSectionWrapper
      style={{
        position: "relative",
        backgroundImage: "linear-gradient(90deg, #09072d 0%, #200f37 100%)",
        overflow: "hidden",
      }}
    >
      <Header />
      <BannerBgImage />
      <HeaderBannerImg>
        <HeaderBannerImgInner src={HomePicPng}>
          {/* <HomePicSvg /> */}
        </HeaderBannerImgInner>
      </HeaderBannerImg>
      <DesktopSection>
        <HeaderBannerWrapper className="header-branner-wrapper">
          <HeaderBannerInner>
            <div>
              <BannerTitleWrap>
                <BannerTitleText>
                  {intl.formatMessage({
                    id: "Cross-chain DEX protocol for better",
                  })}
                  <BannerTitleCompos>
                    {intl.formatMessage({ id: "composability" })}
                  </BannerTitleCompos>
                </BannerTitleText>
                <BannerTitleText>
                  {intl.formatMessage({ id: "DEX on Polkadot" })}
                </BannerTitleText>
              </BannerTitleWrap>
              <BannerDesc>
                {intl.formatMessage({
                  id: "An ultimate, open, and universal cross-chain DEX protocol for building DEX on Polkadot with one click. Make DEX easier, for more people",
                })}
              </BannerDesc>
              <BannerAction>
                <BannerActionItem
                  onClick={() => {
                    window.open("https://app.zenlink.pro")
                  }}
                >
                  <BannerActionItemText>
                    {intl.formatMessage({ id: "Launch APP" })}
                  </BannerActionItemText>

                  <BannerActionItemSvgActive>
                    <LanchSvg />
                  </BannerActionItemSvgActive>
                  <BannerActionItemSvgNormal>
                    <LinkSvg />
                  </BannerActionItemSvgNormal>
                </BannerActionItem>
                <BannerActionItem
                  onClick={() => {
                    window.open("https://wiki.zenlink.pro/")
                  }}
                >
                  <BannerActionItemText>
                    {intl.formatMessage({ id: "Learn more" })}
                  </BannerActionItemText>

                  <BannerActionItemSvgActive>
                    <Lanch2Svg />
                  </BannerActionItemSvgActive>
                  <BannerActionItemSvgNormal>
                    <LinkSvg />
                  </BannerActionItemSvgNormal>
                </BannerActionItem>
              </BannerAction>
            </div>
          </HeaderBannerInner>
        </HeaderBannerWrapper>
      </DesktopSection>
    </DesktopSectionWrapper>
  )
}
