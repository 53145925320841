import React, { useState, useCallback, useRef, useEffect } from "react"
import styled from "styled-components"
import {
  DesktopSectionWrapper,
  DesktopSection,
} from "../Section/DesktopSection"
import HeaderBannerSvg from "../../images/v2/HeaderBanner.svg"
import FrameWork from "./Framework"
import ZenlinkDexModule from "./ZenlinkDexModule"
import { breakpoints } from "../const"
import { useIntl } from "gatsby-plugin-intl"

const WhatZenlinkWrapper = styled.div``

const WhatZenlinkHeader = styled.div`
  font-size: 40px;
  color: #1c1f28;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 800px;
  text-align: center;
`
const WhatZenlinkHeaderTitle = styled.div`
  font-size: 64px;
  font-weight: bolder;
  color: #1c1f28;
  line-height: 1.2;
  position: relative;
  /* color: #fff; */
  text-align: center;
  /* mix-blend-mode: difference; */
  z-index: 2;
  @media screen and (max-width: ${breakpoints.md}px) {
    font-size: 32px;
  }
`
const WhatZenlinkHeaderBottomTitleWrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 60px;
  overflow: hidden;
  @media screen and (max-width: ${breakpoints.md}px) {
    padding-top: 70px;
    padding-bottom: 50px;
  }
`

const WhatZenlinkSubTitle = styled.div`
  position: relative;
  font-size: 32px;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  margin-top: 150px;
`

const WhatZenlinkDesc = styled.div`
  position: relative;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  z-index: 2;
`
const WhatZenlinkHeaderTitleWrapper = styled.div`
  position: relative;
  overflow: hidden;
  /* display: flex; */
  /* justify-content: center;
  align-items: center; */
  width: 100%;
  font-size: 64px;
  /* color: white; */
  line-height: 77px;
  height: 100%;
  color: white;
  text-align: center;
  z-index: 2;
`
const FrameWorkTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
`
const FrameWorkTitle = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: #11131a;
  line-height: 58px;
  background: linear-gradient(137deg, #f20082 0%, #9c00d5 50%, #3f58f0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export default function (props) {
  const intl = useIntl()
  const domRef = useRef(null)
  const topTitleRef = useRef(null)
  const domBottomRef = useRef(null)
  const domWhiteBgRef = useRef(null)
  const handleScroll = useCallback(e => {
    let topTitleTramslateY = 0
    let bottomTitleTramslateY = 0
    let topWrapperHeight = domRef.current.parentElement.offsetHeight
    let topTitleHeight = topTitleRef.current.offsetHeight
    topTitleTramslateY = (topWrapperHeight - topTitleHeight) / 2
    bottomTitleTramslateY = (topWrapperHeight + topTitleHeight) / 2
    const MAXTRANSY = 620
    const result = {
      translateY: 0,
    }
    if (domWhiteBgRef.current) {
      const client = domWhiteBgRef.current.getBoundingClientRect()
      result.translateY = client.top < 0 ? -client.top : 0
      result.translateY =
        result.translateY > MAXTRANSY ? MAXTRANSY : result.translateY
      topTitleTramslateY = topTitleTramslateY + result.translateY
      bottomTitleTramslateY = bottomTitleTramslateY - result.translateY
    }
    topTitleRef.current.style.transform = `translateY(${topTitleTramslateY}px)`
    if (domBottomRef.current) {
      let bottomTitleWrapperPaddingTopPx = getComputedStyle(
        domBottomRef.current.parentElement
      ).paddingTop
      let bottomTitleWrapperPaddingTop = Number(
        bottomTitleWrapperPaddingTopPx.replace("px", "") || 0
      )

      bottomTitleTramslateY =
        bottomTitleTramslateY + bottomTitleWrapperPaddingTop
      domBottomRef.current.style.transform = `translateY(${-(bottomTitleTramslateY >
      0
        ? bottomTitleTramslateY
        : 0)}px)`
    }
  }, [])
  useEffect(() => {
    handleScroll()
    const handle = window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [handleScroll])
  return (
    <>
      <DesktopSectionWrapper
        ref={domWhiteBgRef}
        id="howzenlinkwork"
        style={{
          background: `#FFFFFF`,
          position: "relative",
        }}
      >
        <DesktopSection>
          <WhatZenlinkWrapper>
            <WhatZenlinkHeader>
              <WhatZenlinkHeaderTitleWrapper
                ref={domRef}
                style={{
                  // opacity: 0.5,
                  transform: `translate(0px, 0px)`,
                }}
              >
                <WhatZenlinkHeaderTitle ref={topTitleRef}>
                  {intl.formatMessage({ id: "How does Zenlink work" })}
                </WhatZenlinkHeaderTitle>
              </WhatZenlinkHeaderTitleWrapper>
            </WhatZenlinkHeader>
          </WhatZenlinkWrapper>
        </DesktopSection>
      </DesktopSectionWrapper>
      <DesktopSectionWrapper
        style={{
          background: "linear-gradient(90deg, #09072D 0%, #200F37 100%)",
          top: "800px",
          // background: `linear-gradient(90deg, #09072D 0%, #200F37 100%)`,
          // background: `black`,
          zIndex: "5",
        }}
      >
        <DesktopSection
          style={
            {
              // overflow: "hidden",
            }
          }
        >
          <WhatZenlinkHeaderBottomTitleWrapper>
            <WhatZenlinkHeaderTitle
              ref={domBottomRef}
              style={{
                color: "white",
                textAlign: "center",
                transform: `translateY(${-32}px)`,
              }}
            >
              {intl.formatMessage({ id: "How does Zenlink work" })}
            </WhatZenlinkHeaderTitle>
          </WhatZenlinkHeaderBottomTitleWrapper>

          <FrameWork />
        </DesktopSection>
      </DesktopSectionWrapper>
    </>
  )
}
