import React, { useEffect } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import SEO from "../components/seo"
import Layout from "../components/Layout"
import Banner from "../components/Banner"
import Footer from "../components/Footer"
import WhatZenlink from "../components/WhatZenlink"
import WhyZenlink from "../components/WhyZenlink"
import HowZenlinkWork from "../components/HowZenlinkWork"
import RoadMap from "../components/RoadMap"
import Blog from "../components/Blog"
import Partners from "../components/Partners"
import Investors from "../components/Investors"
import ZenlinkFoundation from "../components/ZenlinkFoundation"

import Section from "../components/Section"
import Main from "../components/Main"
import Fixed from "../components/Fixed"
export default function Home() {
  return (
    <Layout>
      <SEO title={"Zenlink Network"} />
      <Banner />
      <WhatZenlink />
      <WhyZenlink />
      <HowZenlinkWork />
      <RoadMap />
      <Blog />
      <Partners />
      <Investors />
      <ZenlinkFoundation />

      {/* <Main /> */}
      {/* <Section /> */}
      {/* <Footer />
      <Fixed /> */}
    </Layout>
  )
}
