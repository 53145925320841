import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { BaseInner } from "../baseComponents"
import { useIntl } from "gatsby-plugin-intl"
import "./index.css"
import {
  changeLocale,
  injectIntl,
  IntlContextConsumer,
} from "gatsby-plugin-intl"

import { MediaContextProvider, Media } from "../media"

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
`

const StyledSection = styled.section`
  display: flex;
  background-image: linear-gradient(180deg, #1a1a1a 0%, #1a1a1a 100%);

  align-items: center;
  flex-direction: column;
  padding-bottom: 160px;

  @media screen and (max-width: 1080px) {
    padding: 160px 0px;
  }

  @media screen and (max-width: 768px) {
    padding: 20px 0px;
  }
`

const StyledSection2 = styled.section`
  display: flex;
  background: #ffffff;

  align-items: center;
  flex-direction: column;
  padding-bottom: 0px;
  justify-content: center;

  @media screen and (max-width: 1080px) {
    padding: 160px 0px;
  }

  @media screen and (max-width: 768px) {
    padding: 20px 0px 0px;
  }
`

const Left = styled.div`
  max-width: 500px;
  p {
    margin-top: 50px;
    font-size: 30px;
    line-height: 32px;
  }
`

const Line = styled.div`
  display: inline-flex;
  width: 100%;
  height: 56px;
  background: #fff46f;
  border: 0;
  padding-bottom: 0;
`

export const InnerSection = styled(BaseInner)`
  display: flex;
  align-items: center;
  min-height: 200px;
  margin-top: 120px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    & > div:second-of-type {
      padding-bottom: 1px;
    }
  }

  @media screen and (min-width: 768px) {
    & > div:last-of-type {
      margin-left: 37px;
    }
  }

  @media screen and (max-width: 768px) {
    & > div:last-of-type {
      margin-top: 30px;
    }
  }
`

const InnerSection2 = styled(BaseInner)`
  display: flex;
  align-items: start;
  justify-items: space-between;
  padding: 100px 160px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 0px 20px;
  }
`

const SectionTitle = styled.p`
  font-size: 40px;
  color: #ffffff;
  width: 500px;
  text-align: center;
  margin-top: 120px;

  @media screen and (max-width: 768px) {
    margin-top: 40px;
    max-width: 350px;
  }
`

const Section2Title = styled.p`
  font-size: 40px;
  color: #1a1a1a;
  width: 500px;
  text-align: center;
  margin-top: 100px;
  padding-bottom: 40px;

  @media screen and (max-width: 768px) {
    margin-top: 10px;
    max-width: 350px;
  }
`

const SectionRoadTitle = styled.p`
  font-size: 40px;
  color: #1a1a1a;
  width: 350px;
  text-align: center;
  margin-top: 80px;
  margin-bottom: 60px;

  @media screen and (max-width: 768px) {
    margin-top: 50px;
    margin-bottom: 40px;
  }
`

const SectionModuleTitle = styled.h2`
  @media screen and (max-width: 768px) {
    max-width: 350px;
  }
  font-size: 20px;
  color: #ffffff;
  margin: 0;
`

const SectionModuleText = styled.span`
  display: flex;
  margin-top: 20px;
  opacity: 0.8;
  font-size: 14px;
  color: #ffffff;
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Item2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  justify-self: end;
`

const ItemImgWrap = styled.div`
  display: flex;
  width: 100px;
  height: 100px;
  padding: 5px;
`
const ItemTitle = styled.h4`
  font-size: 16px;
  color: #1a1a1a;
  line-height: 24px;
  margin-top: 5px;
`
const ItemDescribe = styled.p`
  font-size: 14px;
  color: #1a1a1a;
  line-height: 24px;
  max-width: 400px;
`

const roadWrap = styled.div`
  margin-left:100px;
  padding-left:100px;
  width: 0px ;
  @media screen and (max-width: 768px) {
    display:flex;
    justify-content:center;
    align-items:center;'
    margin-left:10px;
    padding-left:10px;
  }
`
const PartnersWrap = styled.div`
  width: 960px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 40px;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    width: 375px;
    display: flex;
    justify-content: space-around;
  }
`
const ImgWrap = styled.div`
  width: 161px;
  margin-bottom: 50px;
  text-align: center;
  font-size: 0;
  @media screen and (max-width: 768px) {
    width: 150px;
    margin: 0 0 40px 35px;
    text-align: left;
  }
`
const ImgContent = styled.img`
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width:79%;
  }
`

export default function () {
  // const size = useWidthSize()
  const data = useStaticQuery(graphql`
    query {
      section1Image: file(relativePath: { eq: "section-1.png" }) {
        childImageSharp {
          fixed(width: 186) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      section2Image: file(relativePath: { eq: "section-2.png" }) {
        childImageSharp {
          fixed(width: 280) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      section3Image: file(relativePath: { eq: "section-3.png" }) {
        childImageSharp {
          fixed(width: 186) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      section4Image: file(relativePath: { eq: "section-4.png" }) {
        childImageSharp {
          fixed(width: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      section5Image: file(relativePath: { eq: "section-5.png" }) {
        childImageSharp {
          fixed(width: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      sectionRoadImage: file(relativePath: { eq: "roadmap.png" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      roadMapBigZh: file(relativePath: { eq: "roadmap3.png" }) {
        childImageSharp {
          fixed(width: 960) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      roadMapBigEn: file(relativePath: { eq: "roadmap3.png" }) {
        childImageSharp {
          fixed(width: 960) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      roadMapSmallZh: file(relativePath: { eq: "roadmap2.png" }) {
        childImageSharp {
          fixed(width: 340) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      roadMapSmallEn: file(relativePath: { eq: "roadmap1.png" }) {
        childImageSharp {
          fixed(width: 340) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      roadMapHashkey: file(relativePath: { eq: "haskkey.png" }) {
        childImageSharp {
          fixed(width: 115) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const lgIconList = [
    {
      id: 1,
      path: require("../../images/hashkey.png"),
      url: "https://hub.hashkey.com/",
    },
    {
      id: 2,
      path: require("../../images/iosg.png"),
      url: "http://www.iosg.vc/",
    },
    {
      id: 3,
      path: require("../../images/continue capital.png"),
      url: "https://continue.capital/",
    },
    { id: 4, path: require("../../images/youbi.png") },
    { id: 5, path: require("../../images/ci.png") },
    {
      id: 6,
      path: require("../../images/chainx.png"),
      url: "https://chainx.org/",
    },
    {
      id: 7,
      path: require("../../images/acala.png"),
      url: "https://acala.network/",
    },
    {
      id: 8,
      path: require("../../images/bifrost-blue.png"),
      url: "www.baidu.com",
    },
    { id: 9, path: require("../../images/stafi.png"), url: "www.baidu.com" },
    {
      id: 10,
      // path: require("../../images/web3 foundation_grants_badge_white.png"),
      // url: "https://web3.foundation/",
    },
  ]
  const renderChoice = currentLocale => {
    if (currentLocale == "en") {
      return (
        <MediaContextProvider>
          <Media lessThan="lg">
            <Img fixed={data.roadMapSmallEn.childImageSharp.fixed} />
          </Media>
          <Media greaterThanOrEqual="lg">
            <Img fixed={data.roadMapBigEn.childImageSharp.fixed} />
          </Media>
        </MediaContextProvider>
      )
    } else {
      return (
        <MediaContextProvider>
          <Media lessThan="lg">
            <Img fixed={data.roadMapSmallZh.childImageSharp.fixed} />
          </Media>
          <Media greaterThanOrEqual="lg">
            <Img fixed={data.roadMapBigZh.childImageSharp.fixed} />
          </Media>
        </MediaContextProvider>
      )
    }
  }

  const renderMediumSection = () => {
    return (
      <MediaContextProvider>
        <Media lessThan="lg" id="SectionTitles">
          (
          <StyledSection>
            <SectionTitle>ZenLink DEX Network</SectionTitle>
            <InnerSection>
              <Left>
                <SectionModuleTitle>
                  {intl.formatMessage({ id: "section1-1" })}
                </SectionModuleTitle>
                <SectionModuleText>
                  {intl.formatMessage({ id: "section1-2" })}
                </SectionModuleText>
              </Left>
              <Img fixed={data.section1Image.childImageSharp.fixed} />
            </InnerSection>
            <InnerSection>
              <Left>
                <SectionModuleTitle>
                  {intl.formatMessage({ id: "section2-1" })}
                </SectionModuleTitle>
                <SectionModuleText>
                  {intl.formatMessage({ id: "section2-2" })}
                </SectionModuleText>
              </Left>
              <Img fixed={data.section2Image.childImageSharp.fixed} />
            </InnerSection>
            <InnerSection>
              <Left>
                <SectionModuleTitle>
                  {intl.formatMessage({ id: "section3-1" })}
                </SectionModuleTitle>
                <SectionModuleText>
                  {intl.formatMessage({ id: "section3-2" })}
                </SectionModuleText>
              </Left>
              <Img fixed={data.section3Image.childImageSharp.fixed} />
            </InnerSection>
          </StyledSection>
        </Media>
        <Media greaterThanOrEqual="lg">
          <StyledSection>
            <SectionTitle id="SectionTitles">ZenLink DEX Network</SectionTitle>
            <InnerSection>
              <Left>
                <SectionModuleTitle>
                  {intl.formatMessage({ id: "section1-1" })}
                </SectionModuleTitle>
                <SectionModuleText>
                  {intl.formatMessage({ id: "section1-2" })}
                </SectionModuleText>
              </Left>
              <Img fixed={data.section1Image.childImageSharp.fixed} />
            </InnerSection>
            <InnerSection>
              <Img fixed={data.section2Image.childImageSharp.fixed} />
              <Left>
                <SectionModuleTitle>
                  {intl.formatMessage({ id: "section2-1" })}
                </SectionModuleTitle>
                <SectionModuleText>
                  {intl.formatMessage({ id: "section2-2" })}
                </SectionModuleText>
              </Left>
            </InnerSection>
            <InnerSection>
              <Left>
                <SectionModuleTitle>
                  {intl.formatMessage({ id: "section3-1" })}
                </SectionModuleTitle>
                <SectionModuleText>
                  {intl.formatMessage({ id: "section3-2" })}
                </SectionModuleText>
              </Left>
              <Img fixed={data.section3Image.childImageSharp.fixed} />
            </InnerSection>
          </StyledSection>
        </Media>
      </MediaContextProvider>
      // <Media
      //   queries={{
      //     small: "(max-width: 749px)",
      //     big: "(min-width: 750px)",
      //   }}
      // >

      //   {matches => (
    )
  }

  const intl = useIntl()
  return (
    <Content>
      {renderMediumSection()}
      <StyledSection2>
        <Section2Title>Zenlink DEX Module</Section2Title>
        <InnerSection2>
          <Item>
            <ItemImgWrap>
              <Img fixed={data.section4Image.childImageSharp.fixed} />
            </ItemImgWrap>
            <ItemTitle>
              {intl.formatMessage({ id: "dex-section-1-1" })}
            </ItemTitle>
            <ItemDescribe>
              {intl.formatMessage({ id: "dex-section-1-2" })}
            </ItemDescribe>
          </Item>

          <Item2>
            <ItemImgWrap>
              <Img fixed={data.section5Image.childImageSharp.fixed} />
            </ItemImgWrap>
            <ItemTitle>
              {intl.formatMessage({ id: "dex-section-2-1" })}
            </ItemTitle>
            <ItemDescribe>
              {intl.formatMessage({ id: "dex-section-2-2" })}
            </ItemDescribe>
          </Item2>
        </InnerSection2>
        <SectionRoadTitle id="Roadmap">Roadmap</SectionRoadTitle>

        <IntlContextConsumer>
          {({ languages, language: currentLocale }) => (
            <roadWrap>{renderChoice(currentLocale)}</roadWrap>
          )}
        </IntlContextConsumer>
        <div className="bgcontent">
          <SectionRoadTitle className="bgcontent-title">
            Partners
          </SectionRoadTitle>
          <PartnersWrap className="bgcontent-wrap">
            {lgIconList.map(item => (
              // <img key={item.id} src={item.path}></img>
              <ImgWrap key={item.id}>
                <ImgContent src={item.path}></ImgContent>
              </ImgWrap>
            ))}
          </PartnersWrap>
        </div>
      </StyledSection2>
    </Content>
  )
}
