// import { GridWrap, GridRow, GridColumn } from "emotion-flex-grid"
import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { BaseInner } from "../baseComponents"
import { useIntl } from "gatsby-plugin-intl"
import Media from "react-media"

const Content = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  align-items: center;
  max-width: 100%;
`

const StyledSection = styled(BaseInner)`
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  justify-items: center;
  padding: 160px 20px;
  max-width: 1208px;
  grid-row-gap: 30px;

  @media screen and (max-width: 450px) {
    grid-template-columns: 100%;
    grid-template-rows: 250px 250px 250px 250px;
    padding: 20px 20px;
    align-items: center;
  }

  @media screen and (min-width: 450px) {
    grid-template-columns: 100%;
    grid-template-rows: 250px 250px 250px 250px;
    padding: 20px 60px;
  }

  @media screen and (min-width: 780px) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
    width: 100%;
    padding: 100px 160px;
  }
`

const ItemLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-self: start;
  justify-self: start;

  @media screen and (max-width: 780px) {
    height: 200px;
    align-items: center;
    justify-content: center;
    justify-self: center;
  }

  @media screen and (min-width: 1080px) {
    height: 200px;
  }
`

const ItemRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-self: end;

  @media screen and (max-width: 780px) {
    height: 200px;
    align-items: center;
    justify-content: center;
    justify-self: center;
  }

  @media screen and (min-width: 1080px) {
    height: 200px;
  }
`

const ItemImgWrap = styled.div`
  display: flex;
  width: 100px;
  height: 100px;
  padding: 5px;
`
const ItemTitle = styled.h4`
  font-size: 16px;
  color: #1a1a1a;
  line-height: 24px;
  margin-top: 5px;

  @media screen and (max-width: 780px) {
    margin-top: 10px;
  }
`
const ItemDescribe = styled.div`
  font-size: 14px;
  color: #1a1a1a;
  line-height: 24px;
  max-width: 300px;

  @media screen and (max-width: 450px) {
    padding-top: 5px;
  }
`

export default function () {
  const data = useStaticQuery(graphql`
    query {
      mianImage1: file(relativePath: { eq: "main-1.png" }) {
        childImageSharp {
          fixed(width: 84) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      mianImage2: file(relativePath: { eq: "main-2.png" }) {
        childImageSharp {
          fixed(width: 84) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      mianImage3: file(relativePath: { eq: "main-3.png" }) {
        childImageSharp {
          fixed(width: 84) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      mianImage4: file(relativePath: { eq: "main-4.png" }) {
        childImageSharp {
          fixed(width: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const renderChoice = () => {
    return (
      <Media
        queries={{
          small: "(max-width: 749px)",
          big: "(min-width: 750px)",
        }}
      >
        {matches => (
          <Content id="content">
            {matches.small && (
              <StyledSection>
                <ItemLeft>
                  <ItemImgWrap>
                    <Img fixed={data.mianImage1.childImageSharp.fixed} />
                  </ItemImgWrap>
                  <ItemTitle>
                    {intl.formatMessage({ id: "section-grid-1-1" })}
                  </ItemTitle>
                  <ItemDescribe>
                    {intl.formatMessage({ id: "section-grid-1-2" })}
                  </ItemDescribe>
                </ItemLeft>

                <ItemLeft>
                  <ItemImgWrap>
                    <Img fixed={data.mianImage2.childImageSharp.fixed} />
                  </ItemImgWrap>
                  <ItemTitle>
                    {intl.formatMessage({ id: "section-grid-2-1" })}
                  </ItemTitle>
                  <ItemDescribe>
                    {intl.formatMessage({ id: "section-grid-2-2" })}
                  </ItemDescribe>
                </ItemLeft>
                <ItemLeft>
                  <ItemImgWrap>
                    <Img fixed={data.mianImage3.childImageSharp.fixed} />
                  </ItemImgWrap>
                  <ItemTitle>
                    {intl.formatMessage({ id: "section-grid-3-1" })}
                  </ItemTitle>
                  <ItemDescribe>
                    {intl.formatMessage({ id: "section-grid-3-2" })}
                  </ItemDescribe>
                </ItemLeft>
                <ItemLeft>
                  <ItemImgWrap>
                    <Img fixed={data.mianImage4.childImageSharp.fixed} />
                  </ItemImgWrap>
                  <ItemTitle>
                    {intl.formatMessage({ id: "section-grid-4-1" })}
                  </ItemTitle>
                  <ItemDescribe>
                    {intl.formatMessage({ id: "section-grid-4-2" })}
                  </ItemDescribe>
                </ItemLeft>
              </StyledSection>
            )}
            {matches.big && (
              <StyledSection>
                <ItemLeft>
                  <ItemImgWrap>
                    <Img fixed={data.mianImage1.childImageSharp.fixed} />
                  </ItemImgWrap>
                  <ItemTitle>
                    {intl.formatMessage({ id: "section-grid-1-1" })}
                  </ItemTitle>
                  <ItemDescribe>
                    {intl.formatMessage({ id: "section-grid-1-2" })}
                  </ItemDescribe>
                </ItemLeft>

                <ItemRight>
                  <ItemImgWrap>
                    <Img fixed={data.mianImage2.childImageSharp.fixed} />
                  </ItemImgWrap>
                  <ItemTitle>
                    {intl.formatMessage({ id: "section-grid-2-1" })}
                  </ItemTitle>
                  <ItemDescribe>
                    {intl.formatMessage({ id: "section-grid-2-2" })}
                  </ItemDescribe>
                </ItemRight>
                <ItemLeft>
                  <ItemImgWrap>
                    <Img fixed={data.mianImage3.childImageSharp.fixed} />
                  </ItemImgWrap>
                  <ItemTitle>
                    {intl.formatMessage({ id: "section-grid-3-1" })}
                  </ItemTitle>
                  <ItemDescribe>
                    {intl.formatMessage({ id: "section-grid-3-2" })}
                  </ItemDescribe>
                </ItemLeft>
                <ItemRight>
                  <ItemImgWrap>
                    <Img fixed={data.mianImage4.childImageSharp.fixed} />
                  </ItemImgWrap>
                  <ItemTitle>
                    {intl.formatMessage({ id: "section-grid-4-1" })}
                  </ItemTitle>
                  <ItemDescribe>
                    {intl.formatMessage({ id: "section-grid-4-2" })}
                  </ItemDescribe>
                </ItemRight>
              </StyledSection>
            )}
          </Content>
        )}
      </Media>
    )
  }

  const intl = useIntl()
  return <div>{renderChoice()}</div>
}
