import React, { useState, useCallback, useRef, useEffect } from "react"
import styled from "styled-components"
import {
  DesktopSectionWrapper,
  DesktopSection,
} from "../Section/DesktopSection"
import FrameWork from "./Framework"
import { useIntl } from "gatsby-plugin-intl"
import { breakpoints } from "../const"

const SectionWrapper = styled.div`
  margin-bottom: 160px;
  @media screen and (max-width: ${breakpoints.md}px) {
    margin-bottom: 60px;
  }
`
const Title = styled.div`
  font-size: 64px;
  font-weight: bold;
  color: #ffffff;
  line-height: 77px;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 81px;
  @media screen and (max-width: ${breakpoints.md}px) {
    font-size: 32px;
    margin-top: 70px;
    margin-bottom: 60px;
  }
`

export default function (props) {
  const intl = useIntl()
  return (
    <>
      <DesktopSectionWrapper
        id="investors"
        style={{
          background: "linear-gradient(90deg, #09072D 0%, #200F37 100%)",
        }}
      >
        <DesktopSection
          style={{
            padding: "0px",
          }}
        >
          <Title>{intl.formatMessage({ id: "Investors" })}</Title>
          <SectionWrapper>
            <FrameWork />
          </SectionWrapper>
        </DesktopSection>
      </DesktopSectionWrapper>
    </>
  )
}
